<script setup lang="ts">
import {keys, SystemOption} from "@/types/system";
import {onMounted, ref} from 'vue';
import {genFileId, UploadFile, UploadInstance, UploadProps, UploadRawFile} from "element-plus";
import {useI18n} from "vue-i18n"
import {showMessageBox, showNotification} from "@/services/tools";
import {loadSystemOptions, saveOption, saveSystemOptions} from "@/services/systemService"; // 导入国际化插件

const {t} = useI18n() // 使用国际化
const ai_name = ref("");
const ai_dev = ref("");
const ai_desc = ref("");
const ai_keyword = ref("");
const ai_avatar = ref("");
const avatarPic = ref<File | null>(null) // 头像文件
const avatar_upload = ref<UploadInstance>() // 上传实例
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const check_switch = ref(true)


ai_name.value = SystemOption['ai_name']

const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

// 上传文件超出限制时的处理
const handleExceed: UploadProps['onExceed'] = (files) => {
  try {
    avatar_upload.value!.clearFiles() // 清空文件列表
    const file = files[0] as UploadRawFile
    file.uid = genFileId() // 生成文件 ID
    avatar_upload.value!.handleStart(file) // 开始上传文件
    avatarPic.value = file // 更新头像文件
  } catch (error) {
    console.error('Error handling file exceed:', error) // 打印错误信息
  }
}

// 上传文件状态改变时的处理
const handleChange: UploadProps['onChange'] = (uploadFile: UploadFile) => {
  try {
    ai_avatar.value = ""
    if (uploadFile.status === 'ready' && uploadFile.raw) {
      avatarPic.value = uploadFile.raw as File // 更新头像文件
    }
  } catch (error) {
    console.error('Error handling file change:', error) // 打印错误信息
  }
}

// 上传头像之前的检查
const beforeAvatarUpload = (rawFile: File | null) => {
  try {
    if (!rawFile) return true
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif'].includes(rawFile.type) // 检查文件类型
    if (!isAllowedType) {
      showMessageBox(t, "", t('user.avatar_format'))
      return false
    }
    if (rawFile.size / 1024 / 1024 > 2) {
      showMessageBox(t, "", t('user.avatar_size'))
      return false
    }

    avatarPic.value = rawFile
    return true
  } catch (error) {
    console.error('Error before uploading avatar:', error) // 打印错误信息
    return false
  }
}

const save_check = async () => {

  SystemOption['check_switch'] = check_switch.value ? "1" : "0"
  await saveSystemOptions(SystemOption, null)

  if (check_switch.value) {
    await saveOption(t, 'check', ['ak', 'sk']);
  } else {
    showNotification("success", t(`chat.check`), `${t(`chat.check`)} ${t("message.save-options-success")}`);
  }
}


const save_ai = async () => {
  try {
    SystemOption['ai_keyword'] = ai_keyword.value
    SystemOption['ai_name'] = ai_name.value
    SystemOption['ai_desc'] = ai_desc.value
    SystemOption['ai_dev'] = ai_dev.value

    if (!beforeAvatarUpload(avatarPic.value)) return
    SystemOption['ai_avatar'] = await saveSystemOptions(SystemOption, avatarPic.value)
    showNotification("success", t(`chat.ai`), `${t(`chat.ai`)} ${t("message.save-options-success")}`);
  } catch (error) {
    console.error('保存失败:', error);
  }
};


onMounted(async () => {
  try {
    await loadSystemOptions()
    ai_name.value = SystemOption['ai_name']
    ai_desc.value = SystemOption['ai_desc']
    ai_dev.value = SystemOption['ai_dev']
    ai_avatar.value = SystemOption['ai_avatar']
    ai_keyword.value = SystemOption['ai_keyword']
    keys.check_ak.value = SystemOption['check_ak']
    keys.check_sk.value = SystemOption['check_sk']
    check_switch.value = (SystemOption['check_switch'] == "1")

  } catch (error) {
    console.error('初始化失败:', error);
  }
});

</script>

<template>
  <el-row :gutter="20">
    <el-col :span="8">
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.ai") }}
          </div>
        </template>
        <el-text type="primary" size="small">
          {{ $t("chat.ai-info") }}
        </el-text>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.ai-name") }}</span>
          <el-input
              v-model=ai_name
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.ai-dev") }}</span>
          <el-input
              v-model=ai_dev
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.ai-keyword") }}</span>
          <el-input
              v-model=ai_keyword
              clearable
          />
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.ai-desc") }}</span>
          <el-input
              v-model=ai_desc
              :rows="2"
              resize="none"
              type="textarea"
          />
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.ai-avatar") }}</span>
          <div v-if="ai_avatar" class="div-row">
            <el-image
                :src="ai_avatar"
                alt="Preview Image"
                fit="cover"
                class="avatar-preview"
            />
          </div>
          <el-upload list-type="picture-card"
                     :on-exceed="handleExceed"
                     :on-change="handleChange"
                     :on-preview="handlePictureCardPreview"
                     :limit="1"
                     :auto-upload="false"
                     ref="avatar_upload">
            <font-awesome-icon icon="circle-plus" class="avatar-uploader-icon"/>
          </el-upload>
          <el-dialog v-model="dialogVisible" class="preview" width="400px">
            <el-image
                :src="dialogImageUrl"
                alt="Preview Image"

                class="preview_pic"
            />
          </el-dialog>
        </div>
        <template #footer>
          <el-button type="primary" round @click="save_ai">
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.check") }}
            <el-switch
                v-model=check_switch
                inline-prompt
                :active-text="$t('message.yes')"
                :inactive-text="$t('message.no')"
            />
          </div>
        </template>
        <el-text type="primary" size="small">
          {{ $t("chat.check-desc") }}
        </el-text>
        <div class="system-content">
          <span class="system-label"> {{ $t("chat.check-ak") }}</span>
          <el-input
              v-model=keys.check_ak.value
              clearable
              show-password
              :disabled=!check_switch
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label"> {{ $t("chat.check-sk") }}</span>
          <el-input
              v-model=keys.check_sk.value
              clearable
              show-password
              :disabled=!check_switch
          >
          </el-input>
        </div>

        <template #footer>
          <el-button type="primary" round  @click="save_check">
             <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
    </el-col>
    <el-col :span="8">

    </el-col>
    <el-col :span="8">

    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';


.el-card :deep(.el-card__header) {
  background-color: $menu-hover-bg;
  border: 0;
  padding: 10px 20px 10px 20px;

  html.dark & {
    background-color: $menu-sub-dark-bg
  }
}

.el-card :deep(.el-card__footer) {
  border: 0;
  justify-content: flex-end;
  display: flex;
  padding: 0 15px 15px 0;
}


.system-content {
  display: flex;
  align-items: center;
  padding-top: 8px;
  gap: 10px;
}

.system-label {
  white-space: nowrap;
  font-size: 14px;
}


.avatar-uploader-icon {
  color: $header-font-color-light;
  width: 50px;
  height: 50px;
  text-align: center;

  html.dark & {
    color: $menu-sub-dark-bg;
  }
}

.avatar-uploader-icon:hover {
  color: $menu-hover-bg;

  html.dark:hover & {
    color: $header-font-color-light;
  }
}


.preview_pic {
  width: 360px;
  height: auto; /* 高度自适应 */
  display: block;
  margin: 10px auto; /* 图片居中 */
  border-radius: 20px;
}

.avatar-preview {
  height: 148px;
  width: 148px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}


.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 隐藏超出容器的部分 */
  border: 0;
  margin-right: 5px;
}
</style>