import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "system-content" }
const _hoisted_3 = { class: "system-label" }
const _hoisted_4 = { class: "system-content" }
const _hoisted_5 = { class: "system-label" }
const _hoisted_6 = { class: "system-content" }
const _hoisted_7 = { class: "system-label" }
const _hoisted_8 = { class: "system-content" }
const _hoisted_9 = { class: "system-label" }
const _hoisted_10 = { class: "system-content" }
const _hoisted_11 = { class: "system-label" }
const _hoisted_12 = {
  key: 0,
  class: "div-row"
}
const _hoisted_13 = { class: "card-header" }
const _hoisted_14 = { class: "system-content" }
const _hoisted_15 = { class: "system-label" }
const _hoisted_16 = { class: "system-content" }
const _hoisted_17 = { class: "system-label" }

import {keys, SystemOption} from "@/types/system";
import {onMounted, ref} from 'vue';
import {genFileId, UploadFile, UploadInstance, UploadProps, UploadRawFile} from "element-plus";
import {useI18n} from "vue-i18n"
import {showMessageBox, showNotification} from "@/services/tools";
import {loadSystemOptions, saveOption, saveSystemOptions} from "@/services/systemService"; // 导入国际化插件


export default /*@__PURE__*/_defineComponent({
  __name: 'system_m',
  setup(__props) {

const {t} = useI18n() // 使用国际化
const ai_name = ref("");
const ai_dev = ref("");
const ai_desc = ref("");
const ai_keyword = ref("");
const ai_avatar = ref("");
const avatarPic = ref<File | null>(null) // 头像文件
const avatar_upload = ref<UploadInstance>() // 上传实例
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const check_switch = ref(true)


ai_name.value = SystemOption['ai_name']

const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

// 上传文件超出限制时的处理
const handleExceed: UploadProps['onExceed'] = (files) => {
  try {
    avatar_upload.value!.clearFiles() // 清空文件列表
    const file = files[0] as UploadRawFile
    file.uid = genFileId() // 生成文件 ID
    avatar_upload.value!.handleStart(file) // 开始上传文件
    avatarPic.value = file // 更新头像文件
  } catch (error) {
    console.error('Error handling file exceed:', error) // 打印错误信息
  }
}

// 上传文件状态改变时的处理
const handleChange: UploadProps['onChange'] = (uploadFile: UploadFile) => {
  try {
    ai_avatar.value = ""
    if (uploadFile.status === 'ready' && uploadFile.raw) {
      avatarPic.value = uploadFile.raw as File // 更新头像文件
    }
  } catch (error) {
    console.error('Error handling file change:', error) // 打印错误信息
  }
}

// 上传头像之前的检查
const beforeAvatarUpload = (rawFile: File | null) => {
  try {
    if (!rawFile) return true
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif'].includes(rawFile.type) // 检查文件类型
    if (!isAllowedType) {
      showMessageBox(t, "", t('user.avatar_format'))
      return false
    }
    if (rawFile.size / 1024 / 1024 > 2) {
      showMessageBox(t, "", t('user.avatar_size'))
      return false
    }

    avatarPic.value = rawFile
    return true
  } catch (error) {
    console.error('Error before uploading avatar:', error) // 打印错误信息
    return false
  }
}

const save_check = async () => {

  SystemOption['check_switch'] = check_switch.value ? "1" : "0"
  await saveSystemOptions(SystemOption, null)

  if (check_switch.value) {
    await saveOption(t, 'check', ['ak', 'sk']);
  } else {
    showNotification("success", t(`chat.check`), `${t(`chat.check`)} ${t("message.save-options-success")}`);
  }
}


const save_ai = async () => {
  try {
    SystemOption['ai_keyword'] = ai_keyword.value
    SystemOption['ai_name'] = ai_name.value
    SystemOption['ai_desc'] = ai_desc.value
    SystemOption['ai_dev'] = ai_dev.value

    if (!beforeAvatarUpload(avatarPic.value)) return
    SystemOption['ai_avatar'] = await saveSystemOptions(SystemOption, avatarPic.value)
    showNotification("success", t(`chat.ai`), `${t(`chat.ai`)} ${t("message.save-options-success")}`);
  } catch (error) {
    console.error('保存失败:', error);
  }
};


onMounted(async () => {
  try {
    await loadSystemOptions()
    ai_name.value = SystemOption['ai_name']
    ai_desc.value = SystemOption['ai_desc']
    ai_dev.value = SystemOption['ai_dev']
    ai_avatar.value = SystemOption['ai_avatar']
    ai_keyword.value = SystemOption['ai_keyword']
    keys.check_ak.value = SystemOption['check_ak']
    keys.check_sk.value = SystemOption['check_sk']
    check_switch.value = (SystemOption['check_switch'] == "1")

  } catch (error) {
    console.error('初始化失败:', error);
  }
});


return (_ctx: any,_cache: any) => {
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("chat.ai")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_ai
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_text, {
                type: "primary",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("chat.ai-info")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("chat.ai-name")), 1),
                _createVNode(_component_el_input, {
                  modelValue: ai_name.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ai_name).value = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("chat.ai-dev")), 1),
                _createVNode(_component_el_input, {
                  modelValue: ai_dev.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ai_dev).value = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("chat.ai-keyword")), 1),
                _createVNode(_component_el_input, {
                  modelValue: ai_keyword.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ai_keyword).value = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("chat.ai-desc")), 1),
                _createVNode(_component_el_input, {
                  modelValue: ai_desc.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ai_desc).value = $event)),
                  rows: 2,
                  resize: "none",
                  type: "textarea"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("chat.ai-avatar")), 1),
                (ai_avatar.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_el_image, {
                        src: ai_avatar.value,
                        alt: "Preview Image",
                        fit: "cover",
                        class: "avatar-preview"
                      }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_upload, {
                  "list-type": "picture-card",
                  "on-exceed": handleExceed,
                  "on-change": handleChange,
                  "on-preview": handlePictureCardPreview,
                  limit: 1,
                  "auto-upload": false,
                  ref_key: "avatar_upload",
                  ref: avatar_upload
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "circle-plus",
                      class: "avatar-uploader-icon"
                    })
                  ]),
                  _: 1
                }, 512),
                _createVNode(_component_el_dialog, {
                  modelValue: dialogVisible.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((dialogVisible).value = $event)),
                  class: "preview",
                  width: "400px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_image, {
                      src: dialogImageUrl.value,
                      alt: "Preview Image",
                      class: "preview_pic"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.$t("chat.check")) + " ", 1),
                _createVNode(_component_el_switch, {
                  modelValue: check_switch.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((check_switch).value = $event)),
                  "inline-prompt": "",
                  "active-text": _ctx.$t('message.yes'),
                  "inactive-text": _ctx.$t('message.no')
                }, null, 8, ["modelValue", "active-text", "inactive-text"])
              ])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_check
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_text, {
                type: "primary",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("chat.check-desc")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("chat.check-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).check_ak.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(keys).check_ak.value) = $event)),
                  clearable: "",
                  "show-password": "",
                  disabled: !check_switch.value
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("chat.check-sk")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).check_sk.value,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(keys).check_sk.value) = $event)),
                  clearable: "",
                  "show-password": "",
                  disabled: !check_switch.value
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }),
      _createVNode(_component_el_col, { span: 8 })
    ]),
    _: 1
  }))
}
}

})