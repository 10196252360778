import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"} }
const _hoisted_2 = { class: "div-wrapper" }

import LoginForm_up from '@/components/LoginForm-up.vue';
import {ref} from 'vue';
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const {t} = useI18n();
const activeName = ref('userPass')



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LoginForm_up)
    ])
  ]))
}
}

})