<script setup lang="ts">

</script>

<template>
  <el-row>
    <el-col span="10">
      <el-row>

      </el-row>
      <el-row></el-row>
    </el-col>
    <el-col span="14">

    </el-col>
  </el-row>
</template>

<style scoped lang="scss">

</style>