import axios from "@/setups/axios-setup";
import config from "@/services/config";
import {KeyPrefix, keys, KeySuffix, SysConfigResponse, SystemOption, SystemOptionType, TreeNode} from "@/types/system";
import {encryptData, showNotification} from "@/services/tools";

/**
 * 保存系统选项配置
 * @param {SystemOptionType} options - 需要保存的系统配置对象
 * @param {File | null} avatar_pic - 可选的用户头像文件
 * @returns {Promise<string>} - 返回保存成功的消息
 */
export const saveSystemOptions = async (options: SystemOptionType, avatar_pic: File | null): Promise<string | undefined> => {
    try {
        const data = new FormData();
        data.append('options', JSON.stringify(options)); // 如果 options 是一个对象
        if (avatar_pic) {
            data.append('file', avatar_pic);
        }

        const response = await axios.post(`${config.apiSysUrl}/save_configs/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });


        return response.data.message;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'InvalidInput':
                showNotification('warning', '保存系统参数', "无效的输入");
                break;
            case 'OptionNotFound':
                showNotification('warning', '保存系统参数', "系统参数未找到");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
};
/**
 * 加载系统选项配置
 * @returns {Promise<void>} - 加载并更新系统选项
 */
export const loadSystemOptions = async (): Promise<void> => {
    try {
        const response = await axios.get<SysConfigResponse>(`${config.apiSysUrl}/get_sys_config/`);

        for (const item of response.data.message) {
            SystemOption[item.key] = item.value;
        }

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NoSystemOptions':
                showNotification('warning', '保存系统参数', "系统参数未找到");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
};
/**
 * 保存模型参数
 * @param {Function} t - 国际化函数，用于翻译消息内容
 * @param {KeyPrefix} keyPrefix - 键的前缀
 * @param {KeySuffix[]} keysToSave - 需要保存的键的后缀数组
 * @returns {Promise<void>} - 保存操作的 Promise
 */
export const saveOption = async (t: Function, keyPrefix: KeyPrefix, keysToSave: KeySuffix[]): Promise<void> => {
    try {
        keysToSave.forEach(key => {
            const compositeKey = `${keyPrefix}_${key}` as keyof typeof keys;
            SystemOption[compositeKey] = encryptData(keys[compositeKey].value);
        });

        await saveSystemOptions(SystemOption, null);
        showNotification("success", t(`chat.${keyPrefix}`), `${t(`chat.${keyPrefix}`)} ${t("message.save-options-success")}`);
    } catch (error) {
        console.log('保存模型参数时发生错误:', error);
        throw error
    }
};

export const get_nodes_name = async (ids: number[]) => {
    try {
        if (ids.length == 0) return null;
        const nodeIds: number[] = Array.from(ids);
        const response = await axios.post(`${config.apiSysUrl}/get_tree_nodes_name`, nodeIds);
        const temp_node: TreeNode[] = response.data.message
        return temp_node;
    } catch (error: any) {
        let detail = "null"
        if (error) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NoTreeNode':
                showNotification('warning', '获取节点数据', "节点未找到");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取节点标签出错', error.response.data.detail);
                break;
        }
    }
};

export const get_tree = async (tree_name: string) => {
    try {
        const response = await axios.get(`${config.apiSysUrl}/get_tree/${tree_name}`);
        return response.data;
    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'TreeNameNotEmpty':
                showNotification('warning', '知识库', "知识库名不能为空");
                break;
            case 'NoTreeNodeData':
                showNotification('warning', '知识库', "库内无任何目录");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }
};

export const save_tree_node = async (node: TreeNode, del_child_label: boolean, add_child_label: boolean) => {
    try {

        const temp_node = {
            node_id: node.id,
            tree_name: node.tree_name,
            node_name: node.label,
            parent_id: node.parent_id,
            label_names: [],
            label_ids: node.label_ids,
            delete: false
        }
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('node_data', JSON.stringify(temp_node)); // 添加用户数据
        formData.append('del_child_label', String(del_child_label)); // 添加布尔值，转换为字符串
        formData.append('add_child_label', String(add_child_label)); // 添加布尔值，转换为字符串
        const response = await axios.post(`${config.apiSysUrl}/save_treenode/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NodeDataNotEmpty':
                showNotification('warning', '知识库', "节点名不能为空");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }
};

export const del_tree_node = async (tree_name: string, node_id: number, del: boolean = false) => {
    try {
        const del_node = {
            tree_name: tree_name,
            node_id: node_id,
            delete: del,
        };

        const response = await axios.delete(`${config.apiSysUrl}/del_treenode`, {data: del_node});
        return response.data.message;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NoTreeNode':
                showNotification('warning', '知识库', "树节点不存在");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }

};