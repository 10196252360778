//src/services/topicService.ts

import axios from '@/setups/axios-setup'
import {Topic} from '@/types/Topic'
import config from '@/services/config'


export const createOrUpdateTopic = async (topic: Topic) => {
    try {
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('topic_data', JSON.stringify(topic)); // 添加用户数据

        const response = await axios.post(`${config.apiTopicUrl}/topic`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const deleteTopics = async (ids: number[]) => {
    try {
        if (ids.length>0) {
            const response = await axios.delete(`${config.apiTopicUrl}/topic`, {data: ids});
            return response.data.success;
        }
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const getTopics = async (keyword: string | null,  page: number, pagesize: number) => {

    // 构建请求数据对象
    const data = {
        search_keyword: keyword, // 搜索关键字
        page: page,                // 当前页码
        page_size: pagesize       // 每页项目数量
    };

    const response = await axios.post(`${config.apiTopicUrl}/topics`, data);
    return response.data;

};

export const getTopicById = async (tp_Id: number) => {
    try {
        const response = await axios.get(`${config.apiTopicUrl}/topic/${tp_Id}`);
        return response.data.message;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const getTopicsSummary = async () => {
    try {
        const response = await axios.get(`${config.apiTopicUrl}/topics/summary`);
        return JSON.parse(response.data);
    } catch (error) {
        console.log(error)
        throw error
    }
};
