import {defineStore} from 'pinia';
import {Topic} from '@/types/Topic';
import {createOrUpdateTopic, deleteTopics, getTopicById, getTopics, getTopicsSummary} from '@/services/topicService';
import {showNotification} from "@/services/tools";


export const useTopicStore = defineStore('topicStore', {
    state: () => ({
        topicList: [] as Topic[],
        topicSummary: [] as any[],
        topicTotal: 0,
        topic: {} as Topic,
    }),
    actions: {
        async getTopics(keyword: string | null, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getTopics(keyword, page, pageSize);
                this.topicList = response.topics;
                this.topicTotal = response.total;
                localStorage.setItem("topic_page_size", pageSize.toString());

                return response.total;

            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'UserNotFound':
                        showNotification('warning', '认证错误', "用户认证出现未知错误，需要重新登录。");
                        break;
                    case 'DatabaseError':
                        showNotification('warning', '数据库错误', "数据库无法访问");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }
                return 0;
            }
        },
        async getTopicById(topicId: number) {
            try {
                this.topic = await getTopicById(topicId);
                return 0;
            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'InvalidInput':
                        showNotification('error', '查询', "查询参数无效");
                        break;
                    case 'NoData':
                        showNotification('error', '查询', "无数据");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }

            }
        },
        async createOrUpdateTopic(topic: Topic) {
            try {
                const response = await createOrUpdateTopic(topic);
                return response.success
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async del_Topics(topic_ids: number[]) {
            try {
                return await deleteTopics(topic_ids);
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async getTopicSummary() {
            try {
                this.topicSummary = await getTopicsSummary();
            } catch (error) {
                console.error('unknown error: ', error);
            }
        },
    },
});