<script setup lang="ts">
import {onMounted, reactive, ref, watch} from 'vue';
import {ElTable} from 'element-plus';
import {API} from "@/types/API";
import {
  autoHeight,
  formatDate,
  formatText,
  showLoading,
  showMessageBox,
  showNotification
} from "@/services/tools";
import {useI18n} from "vue-i18n";
import {useApiStore} from "@/stores/apiStore";
import NewAPI from "@/components/NewAPI.vue";
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons";
import {useFileStore} from "@/stores/fileStore";
import {get_nodes_name} from "@/services/systemService";

const {t} = useI18n();
const fileStore = useFileStore();
const apiStore = useApiStore();

// 响应式状态
const buttonIcon = ref(faCopy);
const expandedRowKeys = ref<string[]>([]);
const currentPage = ref(1);
const pageSize = ref(20);
const totalItems = ref(0);
const tableData = ref<API[]>([]);
const publish = ref<string>("-1");
const tableHeight = autoHeight(190);
const multipleSelection = ref<API[]>([]);
const apiTable = ref<InstanceType<typeof ElTable> | null>(null);
const showDialog = ref(false);
const showEditDialog = ref(false);
const cur_api = ref<API>();
const searchData = reactive({keyword: ''});

const ps_key = localStorage.getItem("api_page_size");
if (ps_key) {
  pageSize.value = Number(ps_key);
}

// 计算属性优化数据请求
const fetchAPIs = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    expandedRowKeys.value.length = 0
    totalItems.value = await apiStore.getAPIs(searchData.keyword, Number(publish.value), currentPage.value, pageSize.value);
    tableData.value = apiStore.apiList;
  } finally {
    loading.close();
  }
};

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchAPIs);

// 处理分页变更
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchAPIs();
};

// 处理搜索
const searchAPI = fetchAPIs;

// 处理表格选中变化
const handleSelectionChange = (val: API[]) => {
  multipleSelection.value = val;
};

// 统一处理发布/取消发布
const apisPublish = async (isPublish: boolean) => {
  if (multipleSelection.value.length === 0) {
    await showMessageBox(t, t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-51'), t('message.last-one'));
    return;
  }
  const apiIds = multipleSelection.value.map(api => api.id!);
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await apiStore.publish_APIs(apiIds, isPublish);
    tableData.value.forEach(item => {
      if (apiIds.includes(item.id!)) item.publish = isPublish;
    });
    showNotification("success", t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-51'), t("apiconfig.api-success", {count: apiIds.length}));
  } catch (error: any) {
    showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
  } finally {
    loading.close();
  }
};

// 删除 API
const apisDelete = async (cur_row: number) => {
  const apiIds = cur_row !== -1 ? [cur_row] : multipleSelection.value.map(api => api.id!);
  if (apiIds.length === 0) {
    try {
      await showMessageBox(t, t('message.del-title'), t('message.last-one'));
    } catch {
      return;
    }
    return;
  }
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);
    expandedRowKeys.value.length = 0
    await apiStore.del_APIs(apiIds);
    tableData.value = tableData.value.filter(item => !apiIds.includes(item.id!));
    totalItems.value -= apiIds.length;
    showNotification("success", t('message.del-success-title'),
        t('message.del-success-title') + t('apiconfig.api-success', {count: apiIds.length}));
  } catch (error: any) {
    if (error != "cancel") {
      showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
    }
  } finally {
    loading.close();
  }
};

// 显示对话框
const show_dialog = (isNew: boolean, apiData: API | null) => {
  showDialog.value = isNew;
  showEditDialog.value = !isNew;
  cur_api.value = apiData || undefined;
};

// 复制到剪贴板
const copyToClipboard = async (copyText: string) => {
  try {
    await navigator.clipboard.writeText(copyText);
    if (copyText.length > 50) {
      buttonIcon.value = faCheck;
      setTimeout(() => buttonIcon.value = faCopy, 1000);
    } else {
      showNotification("success", t("message.copy"), t("apiconfig.api_path") + t("message.copy-success"));
    }
  } catch (err) {
    showNotification("error", "复制失败", "无法复制到剪贴板");
  }
};

// 处理表格行展开
const handleExpandChange = async (row: API, expandedRows: API[]) => {
  if (!expandedRows.includes(row)) return;
  expandedRowKeys.value = expandedRows.map(item => String(item.id));
  await fileStore.getFilesByIds(JSON.parse(row.knowledge_file_ids || '[]'));
  row.files = fileStore.knowledgeFileList
  row.nodes = (await get_nodes_name(JSON.parse(row.knowledge_node_ids || '[]')))?.map(node => ({
    id: node.id,
    label: node.node_name
  })) || [];
};

// 组件挂载后加载数据
onMounted(fetchAPIs);
</script>


<template>
  <el-row>
    <el-col :span="18" class="left-side">
      <div class="pic-text-display">
        <el-radio-group v-model="publish" @change="searchAPI">
          <el-radio-button :label="$t('message.all')" value="-1" border/>
          <el-radio-button :label="$t('message.enable')" value="1" border/>
          <el-radio-button :label="$t('message.disable')" value="0" border/>
        </el-radio-group>
        <el-divider direction="vertical" style="height: 25px;"/>
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                      @keyup.enter="searchAPI"
                      style="width: 300px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchAPI" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
    </el-col>
    <el-col :span="6" class="right-side">
      <el-button-group>
        <el-button type="primary" @click="show_dialog(true,null)" round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('message.create') }}
        </el-button>
        <el-button type="primary" @click="apisPublish(true)" round>
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.publish') }}
        </el-button>
        <el-button type="primary" @click="apisPublish(false)" round>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.unpublish') }}
        </el-button>
        <el-button type="danger" @click="apisDelete(-1)" round>
          <font-awesome-icon icon="trash-can" class="icon-text-button"/>
          {{ $t('message.delete') }}
        </el-button>
      </el-button-group>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-divider/>
      <el-table
          ref="apiTable"
          :data="tableData"
          row-key="id"
          stripe
          show-overflow-tooltip
          :expand-row-keys="expandedRowKeys"
          :height="tableHeight"
          @selection-change="handleSelectionChange"
          @expand-change="handleExpandChange"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div class="gray-background">
              <el-row>
                <el-col :span="12">
                  <div class="div_col">
                    <div v-if="props.row.api_key">
                      <el-text class="title_text">{{ $t('apiconfig.api_key') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.api_key }}</el-text>
                      <el-button type="primary" size="small" circle plain @click="copyToClipboard(props.row.api_key)"
                                 style="margin-left: 10px">
                        <font-awesome-icon :icon="buttonIcon"/>
                      </el-button>
                    </div>
                    <div v-if="props.row.knowledge_node_ids">
                      <el-text class="title_text">{{ $t('apiconfig.knowledge-node-ids') }}</el-text>
                      <div class="tags-row">
                        <el-tag
                            v-for="tag in props.row.nodes"
                            :key="tag.id"
                            round
                            effect="light"
                        >
                          <div class="truncate-tag">
                            {{ tag.label }}
                          </div>
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" style="align-content: flex-start">
                  <div class="div_col">
                    <div v-if="props.row.create_datetime">
                      <el-text class="title_text">{{ $t('message.create_datetime') }}</el-text>
                      <br>{{ formatDate(t,props.row.create_datetime, 'yyyy-MM-dd HH:mm') }}
                    </div>
                    <div v-if="props.row.knowledge_file_ids">
                      <el-text class="title_text">{{ $t('apiconfig.knowledge-file-ids') }}</el-text>
                      <div class="tags-row">
                        <el-tag
                            v-for="tag in props.row.files"
                            :key="tag.file_id"
                            round
                            effect="light"
                        >
                          <div class="truncate-tag">
                            {{ tag.name }}
                          </div>
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-row>
                  <el-col :span="24">
                    <div class="div_col">
                      <div v-if="props.row.description" style="margin-top: 15px">
                        <el-text class="title_text">{{ $t('apiconfig.description') }}</el-text>
                        <br>
                        <el-text class="multi-line-text">{{ props.row.description }}</el-text>
                      </div>
                      <div v-if="props.row.robot" style="width: 100%">
                        <el-row :gutter="10" class="display-card">
                          <el-divider content-position="left">
                            <div class="title_text">{{ $t('message.menu-11') }}</div>
                          </el-divider>
                          <el-col :span="5">
                            <el-text class="title_text">{{ $t('robot.name') }}</el-text>
                            <br>
                            <el-text class="multi-line-text">{{ props.row.robot.name }}</el-text>&nbsp;&nbsp;<el-tag
                              type="warning" size="small" effect="plain" round>
                            (ID:&nbsp;{{ props.row.robot.rb_id }})
                          </el-tag>
                          </el-col>
                          <el-col :span="7">
                            <el-text class="title_text">{{ $t('robot.description') }}</el-text>
                            <br>
                            <el-text class="multi-line-text">{{ props.row.robot.description }}</el-text>
                          </el-col>
                          <el-col :span="11">
                            <el-text class="title_text">{{ $t('robot.system_role_content') }}</el-text>
                            <br>
                            <el-text class="multi-line-text">{{ props.row.robot.system_role_content }}</el-text>
                          </el-col>
                        </el-row>
                      </div>
                      <div v-if="props.row.topic" style="width: 100%">
                        <el-row :gutter="10" class="display-card">
                          <el-divider content-position="left">
                            <div class="title_text">{{ $t('message.menu-13') }}</div>
                          </el-divider>
                          <el-col :span="7">
                            <el-text class="title_text">{{ $t('topic.name') }}</el-text>
                            <br>
                            <el-text class="multi-line-text">{{ props.row.topic.name }}</el-text>&nbsp;&nbsp;<el-tag
                              type="warning" size="small" effect="plain" round>
                            (ID:&nbsp;{{ props.row.topic.tp_id }})
                          </el-tag>
                          </el-col>
                          <el-col :span="16">
                            <el-text class="title_text">{{ $t('topic.description') }}</el-text>
                            <br>
                            <el-text class="multi-line-text">{{ props.row.topic.description }}</el-text>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column fixed="left" width="30">
          <template #default="{ row }">
            <span>
              <span
                  v-if="row.publish"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot red"
              ></span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('apiconfig.name')" width="300" sortable>
          <template #default="scope">
            <span v-html="scope.row.name"></span>&nbsp;&nbsp;<el-tag type="warning" size="small" effect="plain" round>
            (ID:&nbsp;{{ scope.row.id }})
          </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="api_path"
                         :label="$t('apiconfig.api_path')"
                         class="multi-line-text">
          <template #default="scope">
            <span v-html="formatText(scope.row.api_path)"></span>
            <el-button type="primary" size="small" round plain @click="copyToClipboard(scope.row.api_path)"
                       style="margin-left: 10px">
              <font-awesome-icon :icon="faCopy" class="icon-text-button"/>
              <el-text size="small">{{ $t('message.copy') }}</el-text>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="llm_type" :label="$t('apiconfig.llm_type')" width="150" sortable>
          <template #default="scope">
            <span v-if="scope.row.llm_type === 'main_llm'">{{ $t('chat.main-llm') }}</span>
            <span v-else-if="scope.row.llm_type === 'small_llm'">{{ $t('chat.small-llm') }}</span>
            <span v-else-if="scope.row.llm_type === 'logic_llm'">{{ $t('chat.logic-llm') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="times" :label="$t('apiconfig.times')" width="120" align="center"
                         sortable></el-table-column>
        <el-table-column prop="modify_datetime" :label="$t('message.modify_datetime')" width="150" align="center"
                         sortable>
          <template #default="{ row }">{{ formatDate(t,row.modify_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="120" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(false,row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="apisDelete(row.id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <NewAPI :modelValue="showDialog" @update:isShow="showDialog = $event"
              @refreshList="fetchAPIs"/>
      <NewAPI :modelValue="showEditDialog" :api_info="cur_api" @update:isShow="showEditDialog = $event"
              @refreshList="fetchAPIs"/>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
          @size-change="pageSize = $event"
          @current-change="handlePageChange"
      />

    </el-col>
  </el-row>

</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.sub-table {
  border-radius: 20px;
}


.div_col {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  gap: 10px; /* 设置间距 */
}

.gray-background {
  background-color: $menu-bg; /* 设置为你想要的灰色 */
  padding: 20px; /* 可选：添加一些内边距让内容不贴着边 */
  margin-left: 80px;
  margin-right: 15px;
  border-radius: 20px; /* 添加圆角 */
  html.dark & {
    background-color: $main-background-dark;
  }
}

.el-divider {
  background-color: #EBEDF0;

  html.dark & {
    background-color: $main-background-dark;
  }
}


.el-button {
  width: auto;
}

.el-row {
  height: auto;
}

:deep(.el-divider__text) {
  background-color: $menu-bg !important;
  border-radius: 10px !important;
  padding: 1px 15px 2px 15px;
}

.display-card {
  border-radius: 25px;
  background: white;
  margin-top: 15px;
  padding: 5px 20px 20px 20px;

  html.dark & {
    background: $footer-background-dark;
  }
}


</style>