import axios from 'axios';
import config from '@/services/config'; // 假设这里保存着 API URL、API Key 等配置
import router from "@/router";
import {showNotification} from "@/services/tools";


// 获取 Cookie 中的 CSRF Token
function _getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift() || null;
    }
    return null;
}

// 创建 Axios 实例
const axiosInstance = axios.create({
    baseURL: "",
    timeout: 180000, // 设置超时，避免网络问题导致请求长时间挂起
});

// 设置 Axios 全局默认配置
axiosInstance.defaults.withCredentials = true;  // 全局携带凭据，如 Cookie 等
axiosInstance.defaults.headers.common['Authorization'] = `APIKEY-${config.apiKey}`;  // 全局添加 Authorization 头部

// 请求拦截器：全局添加 CSRF Token
axiosInstance.interceptors.request.use((config) => {
    // 获取 CSRF Token 并添加到请求头
    const csrfToken = _getCookie('csrf_token');
    if (csrfToken) {
        config.headers['X-CSRF-TOKEN'] = csrfToken;
    }

    // 动态调整超时时间
    if (config.url?.includes('/save_knowledge_files')) {
        // 上传文件请求，设置超时为无限制（或设置较长的超时时间）
        config.timeout = 0; // 0表示无限制超时，也可以根据需求设定一个较大的数值
    }

    return config;
}, (error) => {
    // 请求错误处理
    console.error('请求错误: ', error);
    return Promise.reject(error);
});

// 响应拦截器：统一处理 HTTP 响应错误
axiosInstance.interceptors.response.use(
    async (response) => {
        // 请求成功，直接返回数据
        return response;
    },
    async (error) => {
        // 请求失败，根据状态码进行不同的处理
        let status = 0
        if (error.response) {
            status = error.response.status;
        }
        const {useUserStore} = await import('@/stores/userStore');
        const userStore = useUserStore();
        let message;

        switch (status) {
            case 400:
                message = '请求参数错误，请检查输入';
                break;
            case 401:
                message = '认证失败或登录状态已过期，请重新登录' + error.response.data.detail;
                userStore.loginUser = null
                // 重定向到登录页面
                await router.push('/login');
                break;
            case 403:
                message = '没有权限进行此操作';
                break;
            case 404:
                message = '请求的资源不存在';
                break;
            case 500:
                message = error.response.data.code;
                showNotification('error', '服务器内部错误，请稍后再试', message);
                break;
            default:
                message = '发生未知错误，请联系管理员';
                showNotification('error', '未知错误', message);
        }
        console.log(message + "\n" + error)
        return Promise.reject(error);
    }
);

export default axiosInstance;
