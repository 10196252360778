interface GeolocationResult {
    latitude: number;
    longitude: number;
    accuracy?: number;
}

interface SunTimes {
    sunrise: Date;
    sunset: Date;
}

const SUN_CACHE_TTL = 3600_000;
const cache = new Map<string, { data: SunTimes; timestamp: number }>();

class AstronomicalCalculator {
    private static readonly EARTH_OBLIQUITY = 23.4397;
    private static readonly DEG_TO_RAD = Math.PI / 180;
    private static readonly REFRACTION = -0.83; // 修正为负值，表示太阳视高度角

    // 修复1：正确的儒略日计算（无需时区偏移）
    private static julianDay(date: Date): number {
        return date.getTime() / 86400000 + 2440587.5;
    }

    private static solarDeclination(meanAnomaly: number): number {
        const equation = 1.9148 * Math.sin(meanAnomaly) + 0.0200 * Math.sin(2 * meanAnomaly);
        const eclipticLon = meanAnomaly + equation + 282.9372;
        return Math.asin(Math.sin(eclipticLon) * Math.sin(this.EARTH_OBLIQUITY * this.DEG_TO_RAD));
    }

    private static calculateTimeComponents(lat: number, dec: number, date: Date, tzOffset: number): SunTimes {
        const latRad = lat * this.DEG_TO_RAD;
        const hourAngle = Math.acos(-Math.tan(latRad) * Math.tan(dec)) * 180 / Math.PI - this.REFRACTION;

        const adjustTime = (offset: number) => {
            const minutes = 720 + offset * 4 - tzOffset;  // 直接使用正确的时区偏移
            const d = new Date(date);
            d.setUTCFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
            d.setUTCHours(0, minutes, 0, 0);  // 明确设置 UTC 基准
            return d;  // 直接返回 UTC 时间
        };

        return {
            sunrise: adjustTime(-hourAngle),
            sunset: adjustTime(hourAngle)
        };
    }

    static compute(lat: number, lng: number = 0, date: Date = new Date()): SunTimes {
        const julianDay = this.julianDay(date);
        const meanAnomaly = (357.5291 + 0.98560028 * (julianDay - 2451545)) % 360 * this.DEG_TO_RAD;
        const declination = this.solarDeclination(meanAnomaly);
        const tzOffset = Math.round(lng / 15) * 60;

        return this.calculateTimeComponents(lat, declination, date, tzOffset);
    }
}

const getUserLocation = (): Promise<GeolocationResult> => new Promise((resolve, reject) => {
    if (!navigator.geolocation) return reject(new Error('GEOLOCATION_UNSUPPORTED'));

    const timeoutId = setTimeout(() =>
        reject(new Error('TIMEOUT')), 10000);

    navigator.geolocation.getCurrentPosition(
        ({coords}) => {
            clearTimeout(timeoutId);
            resolve({
                latitude: coords.latitude,
                longitude: coords.longitude,
                accuracy: coords.accuracy
            });
        },
        err => {
            clearTimeout(timeoutId);
            reject(new Error(['PERMISSION_DENIED', 'UNAVAILABLE', 'TIMEOUT'][err.code] || 'UNKNOWN'));
        },
        {enableHighAccuracy: true, timeout: 10000}
    );
});

export const getSunriseSunset = async (): Promise<SunTimes> => {
    try {
        // 获取用户位置信息
        const location = await getUserLocation();
        if (!location || !location.latitude || !location.longitude) {
            throw new Error("无法获取有效的经纬度信息");
        }

        const lat = location.latitude;
        const lng = location.longitude;

        // 生成缓存键值，精确到四位小数，并基于当前日期缓存
        const cacheKey = `${lat.toFixed(4)}|${lng.toFixed(4)}|${new Date().toDateString()}`;

        // 检查是否已有缓存数据
        if (cache.has(cacheKey)) {
            const entry = cache.get(cacheKey)!;
            // 如果缓存未过期，则直接返回缓存数据
            if (Date.now() - entry.timestamp < SUN_CACHE_TTL) return entry.data;
        }

        // 计算日出日落时间
        const result = AstronomicalCalculator.compute(lat, lng);

        // 存入缓存
        cache.set(cacheKey, {data: result, timestamp: Date.now()});

        return result;
    } catch (error) {
        console.error("获取日出日落时间失败: ", error);

        // 如果获取经纬度失败或计算失败，返回默认的日出日落时间
        const now = new Date();
        return {
            sunrise: new Date(now.setHours(7, 0, 0, 0)), // 默认日出时间 07:00:00
            sunset: new Date(now.setHours(19, 0, 0, 0))  // 默认日落时间 19:00:00
        };
    }
};