import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = { class: "gray-background" }
const _hoisted_3 = { class: "div_col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "tags-row" }
const _hoisted_7 = { class: "truncate-tag" }
const _hoisted_8 = { class: "div_col" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "tags-row" }
const _hoisted_12 = { class: "truncate-tag" }
const _hoisted_13 = { class: "div_col" }
const _hoisted_14 = {
  key: 0,
  style: {"margin-top":"15px"}
}
const _hoisted_15 = {
  key: 1,
  style: {"width":"100%"}
}
const _hoisted_16 = { class: "title_text" }
const _hoisted_17 = {
  key: 2,
  style: {"width":"100%"}
}
const _hoisted_18 = { class: "title_text" }
const _hoisted_19 = {
  key: 0,
  class: "status-dot green"
}
const _hoisted_20 = {
  key: 1,
  class: "status-dot red"
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { key: 2 }

import {onMounted, reactive, ref, watch} from 'vue';
import {ElTable} from 'element-plus';
import {API} from "@/types/API";
import {
  autoHeight,
  formatDate,
  formatText,
  showLoading,
  showMessageBox,
  showNotification
} from "@/services/tools";
import {useI18n} from "vue-i18n";
import {useApiStore} from "@/stores/apiStore";
import NewAPI from "@/components/NewAPI.vue";
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons";
import {useFileStore} from "@/stores/fileStore";
import {get_nodes_name} from "@/services/systemService";


export default /*@__PURE__*/_defineComponent({
  __name: 'api_lib_m',
  setup(__props) {

const {t} = useI18n();
const fileStore = useFileStore();
const apiStore = useApiStore();

// 响应式状态
const buttonIcon = ref(faCopy);
const expandedRowKeys = ref<string[]>([]);
const currentPage = ref(1);
const pageSize = ref(20);
const totalItems = ref(0);
const tableData = ref<API[]>([]);
const publish = ref<string>("-1");
const tableHeight = autoHeight(190);
const multipleSelection = ref<API[]>([]);
const apiTable = ref<InstanceType<typeof ElTable> | null>(null);
const showDialog = ref(false);
const showEditDialog = ref(false);
const cur_api = ref<API>();
const searchData = reactive({keyword: ''});

const ps_key = localStorage.getItem("api_page_size");
if (ps_key) {
  pageSize.value = Number(ps_key);
}

// 计算属性优化数据请求
const fetchAPIs = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    expandedRowKeys.value.length = 0
    totalItems.value = await apiStore.getAPIs(searchData.keyword, Number(publish.value), currentPage.value, pageSize.value);
    tableData.value = apiStore.apiList;
  } finally {
    loading.close();
  }
};

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchAPIs);

// 处理分页变更
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchAPIs();
};

// 处理搜索
const searchAPI = fetchAPIs;

// 处理表格选中变化
const handleSelectionChange = (val: API[]) => {
  multipleSelection.value = val;
};

// 统一处理发布/取消发布
const apisPublish = async (isPublish: boolean) => {
  if (multipleSelection.value.length === 0) {
    await showMessageBox(t, t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-51'), t('message.last-one'));
    return;
  }
  const apiIds = multipleSelection.value.map(api => api.id!);
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await apiStore.publish_APIs(apiIds, isPublish);
    tableData.value.forEach(item => {
      if (apiIds.includes(item.id!)) item.publish = isPublish;
    });
    showNotification("success", t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-51'), t("apiconfig.api-success", {count: apiIds.length}));
  } catch (error: any) {
    showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
  } finally {
    loading.close();
  }
};

// 删除 API
const apisDelete = async (cur_row: number) => {
  const apiIds = cur_row !== -1 ? [cur_row] : multipleSelection.value.map(api => api.id!);
  if (apiIds.length === 0) {
    try {
      await showMessageBox(t, t('message.del-title'), t('message.last-one'));
    } catch {
      return;
    }
    return;
  }
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);
    expandedRowKeys.value.length = 0
    await apiStore.del_APIs(apiIds);
    tableData.value = tableData.value.filter(item => !apiIds.includes(item.id!));
    totalItems.value -= apiIds.length;
    showNotification("success", t('message.del-success-title'),
        t('message.del-success-title') + t('apiconfig.api-success', {count: apiIds.length}));
  } catch (error: any) {
    if (error != "cancel") {
      showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
    }
  } finally {
    loading.close();
  }
};

// 显示对话框
const show_dialog = (isNew: boolean, apiData: API | null) => {
  showDialog.value = isNew;
  showEditDialog.value = !isNew;
  cur_api.value = apiData || undefined;
};

// 复制到剪贴板
const copyToClipboard = async (copyText: string) => {
  try {
    await navigator.clipboard.writeText(copyText);
    if (copyText.length > 50) {
      buttonIcon.value = faCheck;
      setTimeout(() => buttonIcon.value = faCopy, 1000);
    } else {
      showNotification("success", t("message.copy"), t("apiconfig.api_path") + t("message.copy-success"));
    }
  } catch (err) {
    showNotification("error", "复制失败", "无法复制到剪贴板");
  }
};

// 处理表格行展开
const handleExpandChange = async (row: API, expandedRows: API[]) => {
  if (!expandedRows.includes(row)) return;
  expandedRowKeys.value = expandedRows.map(item => String(item.id));
  await fileStore.getFilesByIds(JSON.parse(row.knowledge_file_ids || '[]'));
  row.files = fileStore.knowledgeFileList
  row.nodes = (await get_nodes_name(JSON.parse(row.knowledge_node_ids || '[]')))?.map(node => ({
    id: node.id,
    label: node.node_name
  })) || [];
};

// 组件挂载后加载数据
onMounted(fetchAPIs);

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 18,
          class: "left-side"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_radio_group, {
                modelValue: publish.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((publish).value = $event)),
                onChange: _unref(searchAPI)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.all'),
                    value: "-1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.enable'),
                    value: "1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.disable'),
                    value: "0",
                    border: ""
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"]),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                style: {"height":"25px"}
              }),
              _createVNode(_component_el_form, {
                ref: "searchForm",
                model: searchData,
                inline: true,
                class: "search-form",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "searchKey",
                    class: "keyword-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: searchData.keyword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchData.keyword) = $event)),
                        placeholder: _ctx.$t('message.search-key'),
                        onKeyup: _withKeys(_unref(searchAPI), ["enter"]),
                        style: {"width":"300px"},
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _unref(searchAPI),
                    circle: "",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 6,
          class: "right-side"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (show_dialog(true,null))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.create')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (apisPublish(true))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "check",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.publish')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (apisPublish(false))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "xmark",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.unpublish')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (apisDelete(-1))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "trash-can",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_divider),
            _createVNode(_unref(ElTable), {
              ref_key: "apiTable",
              ref: apiTable,
              data: tableData.value,
              "row-key": "id",
              stripe: "",
              "show-overflow-tooltip": "",
              "expand-row-keys": expandedRowKeys.value,
              height: _unref(tableHeight),
              onSelectionChange: handleSelectionChange,
              onExpandChange: handleExpandChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "expand" }, {
                  default: _withCtx((props) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                (props.row.api_key)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('apiconfig.api_key')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.api_key), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_el_button, {
                                        type: "primary",
                                        size: "small",
                                        circle: "",
                                        plain: "",
                                        onClick: ($event: any) => (copyToClipboard(props.row.api_key)),
                                        style: {"margin-left":"10px"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_font_awesome_icon, { icon: buttonIcon.value }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.knowledge_node_ids)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('apiconfig.knowledge-node-ids')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_6, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.nodes, (tag) => {
                                          return (_openBlock(), _createBlock(_component_el_tag, {
                                            key: tag.id,
                                            round: "",
                                            effect: "light"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_7, _toDisplayString(tag.label), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 12,
                            style: {"align-content":"flex-start"}
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                (props.row.create_datetime)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('message.create_datetime')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                                      _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),props.row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.knowledge_file_ids)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('apiconfig.knowledge-file-ids')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_11, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.files, (tag) => {
                                          return (_openBlock(), _createBlock(_component_el_tag, {
                                            key: tag.file_id,
                                            round: "",
                                            effect: "light"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_12, _toDisplayString(tag.name), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_13, [
                                    (props.row.description)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                          _createVNode(_component_el_text, { class: "title_text" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t('apiconfig.description')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                                          _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(props.row.description), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (props.row.robot)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                          _createVNode(_component_el_row, {
                                            gutter: 10,
                                            class: "display-card"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_divider, { "content-position": "left" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('message.menu-11')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_el_col, { span: 5 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_text, { class: "title_text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('robot.name')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(props.row.robot.name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _cache[16] || (_cache[16] = _createTextVNode("  ")),
                                                  _createVNode(_component_el_tag, {
                                                    type: "warning",
                                                    size: "small",
                                                    effect: "plain",
                                                    round: ""
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(" (ID: " + _toDisplayString(props.row.robot.rb_id) + ") ", 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_el_col, { span: 7 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_text, { class: "title_text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('robot.description')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(props.row.robot.description), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_el_col, { span: 11 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_text, { class: "title_text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('robot.system_role_content')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(props.row.robot.system_role_content), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (props.row.topic)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                          _createVNode(_component_el_row, {
                                            gutter: 10,
                                            class: "display-card"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_divider, { "content-position": "left" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('message.menu-13')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_el_col, { span: 7 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_text, { class: "title_text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('topic.name')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(props.row.topic.name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _cache[20] || (_cache[20] = _createTextVNode("  ")),
                                                  _createVNode(_component_el_tag, {
                                                    type: "warning",
                                                    size: "small",
                                                    effect: "plain",
                                                    round: ""
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(" (ID: " + _toDisplayString(props.row.topic.tp_id) + ") ", 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_el_col, { span: 16 }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_el_text, { class: "title_text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('topic.description')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(props.row.topic.description), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  width: "30"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", null, [
                      (row.publish)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19))
                        : (_openBlock(), _createElementBlock("span", _hoisted_20))
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('apiconfig.name'),
                  width: "300",
                  sortable: ""
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      innerHTML: scope.row.name
                    }, null, 8, _hoisted_21),
                    _cache[22] || (_cache[22] = _createTextVNode("  ")),
                    _createVNode(_component_el_tag, {
                      type: "warning",
                      size: "small",
                      effect: "plain",
                      round: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" (ID: " + _toDisplayString(scope.row.id) + ") ", 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "api_path",
                  label: _ctx.$t('apiconfig.api_path'),
                  class: "multi-line-text"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      innerHTML: _unref(formatText)(scope.row.api_path)
                    }, null, 8, _hoisted_22),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      round: "",
                      plain: "",
                      onClick: ($event: any) => (copyToClipboard(scope.row.api_path)),
                      style: {"margin-left":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          icon: _unref(faCopy),
                          class: "icon-text-button"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_el_text, { size: "small" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('message.copy')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "llm_type",
                  label: _ctx.$t('apiconfig.llm_type'),
                  width: "150",
                  sortable: ""
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.llm_type === 'main_llm')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t('chat.main-llm')), 1))
                      : (scope.row.llm_type === 'small_llm')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t('chat.small-llm')), 1))
                        : (scope.row.llm_type === 'logic_llm')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t('chat.logic-llm')), 1))
                          : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "times",
                  label: _ctx.$t('apiconfig.times'),
                  width: "120",
                  align: "center",
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "modify_datetime",
                  label: _ctx.$t('message.modify_datetime'),
                  width: "150",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),row.modify_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "120",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(false,row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (apisDelete(row.id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "expand-row-keys", "height"]),
            _createVNode(NewAPI, {
              modelValue: showDialog.value,
              "onUpdate:isShow": _cache[7] || (_cache[7] = ($event: any) => (showDialog.value = $event)),
              onRefreshList: fetchAPIs
            }, null, 8, ["modelValue"]),
            _createVNode(NewAPI, {
              modelValue: showEditDialog.value,
              api_info: cur_api.value,
              "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (showEditDialog.value = $event)),
              onRefreshList: fetchAPIs
            }, null, 8, ["modelValue", "api_info"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[9] || (_cache[9] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[10] || (_cache[10] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50, 100, 200],
              layout: "total, sizes, prev, pager, next, jumper",
              total: totalItems.value,
              onSizeChange: _cache[11] || (_cache[11] = ($event: any) => (pageSize.value = $event)),
              onCurrentChange: handlePageChange
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})