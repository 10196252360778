import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import zh from '@/locales/zh.json';
import ja from '@/locales/ja.json';
import fr from '@/locales/fr.json';
import es from '@/locales/es.json';

const i18n = createI18n({
    legacy: false,
    locale: 'zh',  // 默认使用中文
    fallbackLocale: 'en',  // 如果中文没有相应的翻译，则回退到英文
    messages: {
        en,
        zh,
        ja,
        fr,
        es
    }
});

export default i18n;
