// router/index.ts
import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {checkLogin} from "@/services/tools";

const routes: Array<RouteRecordRaw> = [
    {

        path: '/',
        name: 'home',
        component: () => import('../views/blank.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {

        path: '/robot',
        name: 'robot_manage',
        component: () => import('../views/robot_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {

        path: '/prompt',
        name: 'prompt_manage',
        component: () => import('../views/prompt_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/usermanage',
        name: 'user_manage',
        component: () => import('../views/user_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/topic',
        name: 'topic',
        component: () => import('../views/topic_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/chat_check',
        name: 'chat_check',
        component: () => import('../views/chat_check.vue'),
        meta: {
            requiresAuth: true
        }

    },
    {
        path: '/llm_setup',
        name: 'llm_setup',
        component: () => import('../views/llm_setup.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/system',
        name: 'system',
        component: () => import('../views/system_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/files',
        name: 'files',
        component: () => import('../views/file_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/search.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/vectorization',
        name: 'vectorization',
        component: () => import('../views/vectorization.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/api_manage',
        name: 'api',
        component: () => import('../views/api_lib_m.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/api_check',
        name: 'api_check',
        component: () => import('../views/api_check.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // 添加其他路由配置
    {
        path: '/:pathMatch(.*)*', // 捕获所有未定义路径
        redirect: '/robot' // 重定向到 404 页面
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// 在 Vue Router 设置中
router.beforeEach(async (to, from, next) => {
    const isLoggedIn = await checkLogin();
    // 检查是否已登录
    if (isLoggedIn && (to.name === 'login' || to.name === 'home')) {
        next({name: 'robot_manage'});
        return; // 确保后续逻辑不会执行
    }

    // 检查目标页面是否需要认证
    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        if (!isLoggedIn) {
            next({name: 'login'}); // 未登录则跳转到登录页
        } else {
            next(); // 已登录，且目标页需要认证，正常放行
        }
    } else {
        next(); // 目标页不需要认证，正常放行
    }

    // 如果路由未匹配到任何页面，跳转到 404
    if (!to.matched.length) {
        next({name: 'not_found'}); // 跳转到首页
    }
});


export default router
