import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"padding-right":"10px","width":"160px","align-content":"center"} }
const _hoisted_2 = { style: {"padding-left":"15px","padding-right":"5px","height":"100%"} }
const _hoisted_3 = { style: {"font-size":"13px","align-content":"center"} }
const _hoisted_4 = { class: "right_card" }
const _hoisted_5 = { class: "right_card" }

import chatForm from "@/components/ChatForm.vue"
import {ChatMessage} from '@/types/LLM';
import {onMounted, ref, watch} from "vue";
import {CalendarDateType, CalendarInstance, ElScrollbar} from 'element-plus'
import {useLLMStore} from "@/stores/llmStore";
import {useUserStore} from "@/stores/userStore";
import {autoHeight} from "@/services/tools";


export default /*@__PURE__*/_defineComponent({
  __name: 'chat_check',
  setup(__props) {

const curDate = ref<Date>(new Date());
const cur_topic = ref("")
const context_topic = ref("")
const cur_relevance = ref(false)
const cur_sys_human = ref("")
const context_sys_human = ref("")
const cur_robot = ref("")
const context_robot = ref("")
const calendar = ref<CalendarInstance>()

// 为 chatFormRef 指定正确的类型
const chatFormRef = ref<InstanceType<typeof chatForm> | null>(null);
const llmStore = useLLMStore();
const userStore = useUserStore();
const chat_session = ref('')

const contentHeight = autoHeight(140);

const session_selected = async () => {

  // 查找与该 session_id 对应的会话
  const selectedSession = llmStore.chat_session.find(sess => sess.session_id === chat_session.value);

  if (selectedSession) {
    // 获取 session_id 和 session_name
    const sessionId = selectedSession.session_id;
    const sessionName = selectedSession.session_name;
    if (chatFormRef.value) {
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      await llmStore.loadChaSessionList(chat_session.value, null, null)

      chatFormRef.value.setSession(sessionId, sessionName)
      chatFormRef.value.setMessages(llmStore.chat_history)
    }
  }
}

const selectDate = (val: CalendarDateType) => {
  if (!calendar.value) return
  calendar.value.selectDate(val)
}

const new_session = async (load: boolean) => {
  if (llmStore.chat_history.length == 0 && !load) return
  if (chatFormRef.value) {
    llmStore.chat_history.length = 0
    llmStore.streamAnswer.length = 0

    if (!load) {
      // await handleSelect(new Date)
      llmStore.chat_session.unshift({
        "session_id": Date.now().toString() + "U" + userStore.loginUser!.user_id?.toString(),
        "session_name": "新话题" + Date.now().toString().slice(-6)
      })
      chatFormRef.value.setSession(llmStore.chat_session[0].session_id,
          llmStore.chat_session[0].session_name)
      chat_session.value = llmStore.chat_session[0].session_id
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      chatFormRef.value.setMessages(llmStore.chat_history)
    } else {
      llmStore.chat_session.length = 0
      llmStore.chat_session.push({
        "session_id": Date.now().toString() + "U" + userStore.loginUser!.user_id?.toString(),
        "session_name": "新话题" + Date.now().toString().slice(-6)
      })
      chatFormRef.value.setSession(llmStore.chat_session[0].session_id,
          llmStore.chat_session[0].session_name)
      chat_session.value = llmStore.chat_session[0].session_id
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      chatFormRef.value.setMessages(llmStore.chat_history)
    }
  }
}

const handleSelect = async (date: Date) => {
  try {
    if (date > new Date()) date = new Date()
    curDate.value = date
    if (chatFormRef.value) {
      await llmStore.loadChatList(date, date)
      if (llmStore.chat_history.length > 0) {
        chatFormRef.value.setSession(llmStore.chat_session[0].session_id, llmStore.chat_session[0].session_name);
        chat_session.value = ""
      } else {
        chatFormRef.value.setSession("", "")
        chat_session.value = ""
      }

      // 使用 setMessages 方法
      chatFormRef.value.setMessages(llmStore.chat_history);

    }
  } catch (error) {
    console.log(error)
  }
};

const handleDisplayMsgs = (msgs: ChatMessage[]) => {
  if (msgs.length < 4) {
    context_topic.value = msgs[0].topic
    context_robot.value = msgs[0].robot_content
    context_sys_human.value = msgs[0].sys_content
  } else {
    context_topic.value = msgs[msgs.length - 4].topic
    context_robot.value = msgs[msgs.length - 4].robot_content
    context_sys_human.value = msgs[msgs.length - 4].sys_content
  }

  cur_topic.value = msgs[msgs.length - 2].topic
  cur_robot.value = msgs[msgs.length - 2].robot_content
  cur_relevance.value = msgs[msgs.length - 2].relevance
  cur_sys_human.value = msgs[msgs.length - 2].sys_content

}

watch(curDate, (newDate) => {
  if (newDate && newDate > new Date()) {
    curDate.value = new Date(); // Automatically set to current time if selected date is in the future
  }
});

// 组件挂载后执行的操作
onMounted(async () => {
  await new_session(true)
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_calendar = _resolveComponent("el-calendar")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        span: 3,
        class: "session-list"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            round: "",
            type: "primary",
            style: {"width":"130px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (new_session(false)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "comment-dots",
                class: "icon-text-button"
              }),
              _cache[5] || (_cache[5] = _createTextVNode(" 开始新话题 "))
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(ElScrollbar), { height: _unref(contentHeight) }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(llmStore).chat_session, (sess, index) => {
                  return (_openBlock(), _createBlock(_component_el_radio_group, {
                    modelValue: chat_session.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((chat_session).value = $event)),
                    key: index,
                    onChange: session_selected
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tooltip, {
                        content: sess.session_name,
                        placement: "left"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_radio_button, {
                            value: sess.session_id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_text, {
                                "line-clamp": "1",
                                class: "session-text"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(sess.session_name), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["value"])
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["height"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 16 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(chatForm, {
              ref_key: "chatFormRef",
              ref: chatFormRef,
              onMessagesResponse: handleDisplayMsgs
            }, null, 512)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        span: 5,
        class: "test-right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_calendar, {
            ref_key: "calendar",
            ref: calendar,
            "model-value": curDate.value,
            onInput: handleSelect
          }, {
            header: _withCtx(({ date }) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(date), 1),
              _createVNode(_component_el_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    size: "small",
                    round: "",
                    type: "primary",
                    style: {"width":"45px"},
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (selectDate('prev-month')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('message.prev-month')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    size: "small",
                    round: "",
                    type: "primary",
                    style: {"width":"40px"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (selectDate('today')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('message.today')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    size: "small",
                    round: "",
                    type: "primary",
                    style: {"width":"45px"},
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (selectDate('next-month')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('message.next-month')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model-value"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_descriptions, {
              title: _ctx.$t('chat.context'),
              column: 2,
              border: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('chat.topic'),
                  span: "2",
                  width: "100px",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_text, {
                      "line-clamp": "1",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(context_topic.value), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('message.robot'),
                  span: "2",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      content: context_robot.value,
                      placement: "top-end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          "line-clamp": "2",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(context_robot.value), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["content"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('chat.sys-human'),
                  span: "2",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      content: context_sys_human.value,
                      placement: "top-end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          "line-clamp": "2",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(context_sys_human.value), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["content"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_descriptions, {
              title: _ctx.$t('chat.current-question'),
              column: 2,
              border: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('chat.relevance'),
                  span: "2",
                  width: "100px",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_text, {
                      "line-clamp": "1",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(cur_relevance.value ? _ctx.$t("message.yes") : _ctx.$t("message.no")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('chat.topic'),
                  span: "2",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_text, {
                      "line-clamp": "1",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(cur_topic.value), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('message.robot'),
                  span: "2",
                  width: "50px",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      content: cur_robot.value,
                      placement: "top-end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          "line-clamp": "2",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(cur_robot.value), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["content"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_descriptions_item, {
                  label: _ctx.$t('chat.sys-human'),
                  span: "2",
                  width: "50px",
                  "label-align": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      placement: "top-end",
                      content: cur_sys_human.value
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_text, {
                          "line-clamp": "2",
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(cur_sys_human.value), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["content"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})