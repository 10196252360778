import {onBeforeUnmount,ref } from 'vue'
import Clipboard from 'clipboard'

interface ClipboardAPI {
  initClipboard: (options: ClipboardOptions) => void
  updateButtonText: (button: HTMLElement, text: string) => void
  resetButtonAfterDelay: (button: HTMLElement, originalText: string, delay?: number) => void
}

interface ClipboardOptions {
  selector: string
  successCallback?: (e: Clipboard.Event) => void
  errorCallback?: (e: Clipboard.Event) => void
}

export function useClipboard() {
  const clipboardInstances = ref<Clipboard[]>([])
  const timers = ref(new Map<HTMLElement, number>())

  // 初始化剪贴板
  const initClipboard = (options: ClipboardOptions) => {
    const clipboard = new Clipboard(options.selector, {
      text: trigger => trigger.getAttribute('data-clipboard-text') || ''
    })

    clipboardInstances.value.push(clipboard)

    clipboard.on('success', (e) => {
      options.successCallback?.(e)
    })

    clipboard.on('error', (e) => {
      options.errorCallback?.(e)
    })
  }

  // 按钮文本更新
  const updateButtonText = (button: HTMLElement, text: string) => {
    button.dataset.originalText = button.textContent || ''
    button.textContent = text
  }

  // 延时重置按钮
  const resetButtonAfterDelay = (button: HTMLElement, originalText: string, delay = 1000) => {
    clearExistingTimer(button)
    const timer = setTimeout(() => {
      button.textContent = originalText
      timers.value.delete(button)
    }, delay) as unknown as number
    timers.value.set(button, timer)
  }

  // 清理定时器
  const clearExistingTimer = (button: HTMLElement) => {
    const existingTimer = timers.value.get(button)
    if (existingTimer) {
      clearTimeout(existingTimer)
      timers.value.delete(button)
    }
  }

  // 自动清理
  const destroyAll = () => {
    clipboardInstances.value.forEach(instance => instance.destroy())
    timers.value.forEach((timer, button) => {
      clearTimeout(timer)
      button.textContent = button.dataset.originalText || ''
    })
  }

  onBeforeUnmount(destroyAll)

  return {
    initClipboard,
    updateButtonText,
    resetButtonAfterDelay
  }
}