// src/services/llmService.ts

import axios from '@/setups/axios-setup'
import {ChatErrorCode, ChatMessage, StreamResponse} from '@/types/LLM'
import config from '@/services/config'
import {Prompt} from "@/types/Prompt";


export const save_chat = async (msgs: ChatMessage[]): Promise<string> => {
    if (!msgs) {
        return Promise.reject(new Error("消息不能为空"));
    }

    // 构建请求数据对象
    const data = {
        messages: msgs
    };

    const response = await axios.post(`${config.apiLlmUrl}/save_chat/`, data);
    return response.data
}

export const getChatHistory = async (f_date: Date, t_date: Date): Promise<any> => {

    if (!f_date || !t_date) {
        return Promise.reject(new Error("参数不能为空"));
    }
    // 构建请求数据对象
    const data = {
        from_date: f_date.getTime(),        // 开始日期
        to_date: t_date.getTime(),
        topic: "",
        session_id: ""
    };

    const response = await axios.post(`${config.apiLlmUrl}/get_chat_history_date/`, data);
    return response.data

}

export const getChatSessionHistory = async (session_id: string, f_date: Date | null, t_date: Date | null): Promise<any> => {

    if (!session_id) {
        return Promise.reject(new Error("session_id参数不能为空"));
    }

    // 构建请求数据对象
    let from, to
    if (f_date && t_date) {
        from = f_date.getTime()
        to = t_date.getTime()
    } else {
        from = null
        to = null
    }

    const data = {
        from_date: from,        // 开始日期
        to_date: to,
        topic: "",
        session_id: session_id
    };
    const response = await axios.post(`${config.apiLlmUrl}/get_chat_history_session/`, data);
    return response.data
}

export const getAnswer = async (msgs: ChatMessage[]): Promise<string> => {
    if (!msgs) {
        return Promise.reject(new Error("消息不能为空"));
    }
    // 构建请求数据对象
    const data = {
        messages: msgs
    };

    const response = await axios.post(`${config.apiLlmUrl}/chat/`, data);
    return response.data.content.trim();
};


export const getModels = async (keyword: string | null, page: number, pagesize: number) => {
    // 构建请求数据对象
    const data = {
        search_keyword: keyword, // 搜索关键字
        page: page,                // 当前页码
        page_size: pagesize,       // 每页项目数量
        status: 0            // 没有用占位置而已
    };

    const response = await axios.post(`${config.apiLlmUrl}/models/`, data);
    return response.data;
};

// export const messageCheck = async (msgs: ChatMessage[]) => {
//
//     if (!msgs) {
//         return Promise.reject(new Error("消息不能为空"));
//     }
//     // 构建请求数据对象
//     const data = {
//         messages: msgs
//     };
//     const response = await axios.post(`${config.apiLlmUrl}/chat_check`, data);
//     return response.data.content
//
//
// }

export const chat_stream = async (
    msgs: ChatMessage[],
    abortController: AbortController,
    onMessage: (message: StreamResponse | Prompt) => void
) => {
    if (!msgs || msgs.length === 0) {
        return Promise.reject(new Error("消息不能为空"));
    }

    const data = {messages: msgs};

    try {
        const response = await fetch(`${config.apiLlmUrl}/chat_ai`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `APIKEY-${config.apiKey}`,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            body: JSON.stringify(data),
            credentials: 'include',
            signal: abortController.signal
        });

        // 检查请求是否成功
        if (!response.ok) {
            const errorDetails = await response.text();
            console.error(`请求失败: ${response.status} ${response.statusText}`, errorDetails);
            return Promise.reject(new Error(`请求失败: ${response.statusText}`));
        }

        if (!response.body) {
            return Promise.reject(new Error("响应体为空，请稍后重试"));
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let isFirstChunk = true;

        await processStream(reader, decoder, isFirstChunk, onMessage);
    } catch (error) {
        console.error("请求或处理数据时发生错误:", error);
        return Promise.reject(new Error("系统出错，请稍后重试"));
    }
};

// 处理流的逻辑
const processStream = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    decoder: TextDecoder,
    isFirstChunk: boolean,
    onMessage: (message: StreamResponse | Prompt) => void
) => {
    try {
        let done = false;
        let buffer = ""; // 新增缓冲区

        while (!done) {
            const {done: doneReading, value} = await reader.read();
            done = doneReading;

            if (value) {
                // 解码并追加到缓冲区
                buffer += decoder.decode(value, {stream: true});
                // 处理完整的 NDJSON 行
                let newlineIndex;
                while ((newlineIndex = buffer.indexOf('\n')) >= 0) {
                    const line = buffer.slice(0, newlineIndex);
                    buffer = buffer.slice(newlineIndex + 1);

                    if (line.trim() === "") continue;

                    try {
                        const parsed = JSON.parse(line);
                        if (isFirstChunk) {
                            isFirstChunk = false;
                            await handleFirstChunk(JSON.stringify(parsed), onMessage);
                        } else {
                            onMessage(parsed); // 直接传递解析后的对象
                        }
                    } catch (e) {
                        console.error("JSON 解析失败:", line);
                    }
                }
            }
        }

        // 处理缓冲区剩余内容
        if (buffer.trim()) {
            try {
                onMessage(JSON.parse(buffer));
            } catch (e) {
                console.error("最终块解析失败:", buffer);
            }
        }
    } catch (error: any) {
        if (error.name !== 'AbortError') {
            console.error("请求或处理数据时发生错误:", error);
            throw new Error("读取数据失败，请稍后重试");
        }
    }
};

// 处理第一块数据的逻辑
const handleFirstChunk = async (
    chunkText: string,
    onMessage: (message: StreamResponse | Prompt) => void
) => {
    try {
        let cleanedChunkText = chunkText
            .replace(/[\u200B-\u200D\uFEFF]/g, '')
            .replace(/^\uFEFF/, '')
            .replace(/\\\\u/g, '\\u');

        const firstChunk = JSON.parse(cleanedChunkText);
        if ('success' in firstChunk) {
            switch (firstChunk.success) {
                case ChatErrorCode.SUCCESS:
                    return firstChunk.message;
                case ChatErrorCode.NON_COMPLIANCE:
                    onMessage(firstChunk.message);
                    break;
                default:
                    return Promise.reject(
                        new Error(firstChunk.message || "未知错误，请稍后重试")
                    );
            }
        } else {
            onMessage(firstChunk);
        }
    } catch (error) {
        console.error("解析第一块数据时发生错误:", error);
        throw new Error("解析响应数据失败，请稍后重试");
    }
};
