// src/stores/themeStore.ts
import {defineStore} from 'pinia';

import {getSunriseSunset} from "@/setups/sunrise-setup";

export const useThemeStore = defineStore('theme', {
    state: () => ({
        isDarkMode: false,
        sunrise: 0,
        sunset: 0
    }),
    getters: {
        getIsDarkMode: (state) => state.isDarkMode  // 获取当前暗模式状态
    },
    actions: {
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;
            localStorage.setItem('dark-mode', this.isDarkMode.toString());
            if (this.isDarkMode) {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        },

        async setSunTime() {
            const {sunrise, sunset} = await getSunriseSunset();
            const startTotalSeconds = sunrise.getHours() * 3600 + sunrise.getMinutes() * 60 + sunrise.getSeconds();
            const endTotalSeconds = sunset.getHours() * 3600 + sunset.getMinutes() * 60 + sunset.getSeconds();
            localStorage.setItem("sr", startTotalSeconds.toString());
            localStorage.setItem("ss", endTotalSeconds.toString());
        },
        async initTheme() {
            try {
                const now: Date = new Date();
                const sr: string = localStorage.getItem('sr') || '';
                const ss: string = localStorage.getItem('ss') || '';
                this.sunrise = Number(sr);
                this.sunset = Number(ss);

                const currentTotalSeconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

                this.isDarkMode = currentTotalSeconds < this.sunrise || currentTotalSeconds > this.sunset
                localStorage.setItem('dark-mode', this.isDarkMode.toString());
                if (this.isDarkMode) {
                    document.documentElement.classList.add('dark');
                } else {
                    document.documentElement.classList.remove('dark');
                }
            } catch (error) {
                console.error('Error initializing theme:', error);
            }
        },
    }
});

