import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "language-sel" }

import SidebarMenu from '@/components/SidebarMenu.vue';
import {useUserStore} from '@/stores/userStore';
import {useRouter} from 'vue-router';
import {onBeforeMount, onMounted, ref} from "vue";
import {useThemeStore} from '@/stores/themeStore';
import {useI18n} from 'vue-i18n';
import {useLocaleStore} from '@/stores/localStore';

import {loadSystemOptions} from "@/services/systemService";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const {t} = useI18n();
const {locale: i18nLocale} = useI18n();

const userStore = useUserStore();
const localeStore = useLocaleStore();
const router = useRouter();
const errorMessage = ref('');
const username = ref('');
const avatarUrl = ref('');
const themeStore = useThemeStore();
const isDark = ref(themeStore.isDarkMode);
const curMenuName = ref('')


// 设置响应式的语言状态
const language = ref(t('language.zh'));
const languages = [t('language.zh'), t('language.en'), t('language.ja'), t('language.fr'), t('language.es')]


const toggleDarkMode = () => {
  isDark.value = !isDark.value;
  themeStore.toggleDarkMode();
};

const changeTitle = async (menu_name: string) => {
  curMenuName.value = t("message." + menu_name)
}

const logout = async () => {

  errorMessage.value = await userStore.logout();
  if (errorMessage.value === 'success') {
    await router.push('/login');
  }
}

const getUser = async () => {
  try {
    isDark.value = localStorage.getItem('dark-mode') === 'true';
    const userStore = useUserStore();
    const user = await userStore.loadUser(true);
    username.value = user?.name || '';
    avatarUrl.value = user?.avatar_url || '';
  } catch (error) {
    console.error("Failed to load user:", error);
    username.value = '';
    avatarUrl.value = '';
  }
};

const changeLanguage = () => {
  let temp: ('zh' | 'en' | 'fr' | 'ja' | 'es')

  switch (language.value) {
    case t('language.zh'):
      temp = "zh";
      break
    case t('language.en'):
      temp = "en";
      break
    case t('language.ja'):
      temp = "ja";
      break
    case t('language.fr'):
      temp = "fr"
      break
    case t('language.es'):
      temp = "es"
      break
    default:
      temp = "zh"
  }
  localeStore.setLocale(temp);
  i18nLocale.value = temp;
};

// 执行设置语言操作
onBeforeMount(() => {
  switch (localeStore.getLocale) {
    case "zh":
      language.value = t('language.zh');
      break
    case "en":
      language.value = t('language.en');
      break
    case "ja":
      language.value = t('language.ja');
      break
    case "fr":
      language.value = t('language.fr');
      break
    case "es":
      language.value = t('language.es');
      break
    default:
      language.value = t('language.zh');
  }
  changeLanguage();
});

onMounted(async () => {
  await getUser();
  await loadSystemOptions();
});


return (_ctx: any,_cache: any) => {
  const _component_el_text = _resolveComponent("el-text")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_segmented = _resolveComponent("el-segmented")!
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "title-bg" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 16,
                class: "header-left"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_text, {
                    span: 12,
                    class: "header"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('message.title')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_tag, {
                    class: "header-sub",
                    effect: "light",
                    type: "primary",
                    round: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "bars" }),
                      _createTextVNode(" " + _toDisplayString(curMenuName.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 8,
                class: "header-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_avatar, {
                    src: avatarUrl.value,
                    class: "pic-text-display"
                  }, null, 8, ["src"]),
                  _createVNode(_component_el_text, {
                    class: "pic-text-display",
                    tag: "b"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(username.value), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: isDark.value ? 'info' : 'warning',
                    onClick: toggleDarkMode,
                    circle: "",
                    plain: "",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: isDark.value ? 'moon' : 'sun'
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["type"]),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    plain: "",
                    onClick: logout,
                    round: "",
                    size: "small",
                    style: {"width":"auto"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "person-running",
                        class: "icon-text-button"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('user.logout')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_aside, null, {
            default: _withCtx(() => [
              _createVNode(SidebarMenu, {
                "default-active": "5-1",
                onSelected_menu_item: changeTitle
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      }),
      _createVNode(_component_el_footer, { class: "footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_text, { size: "small" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('message.footer')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_segmented, {
              modelValue: language.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((language).value = $event)),
              options: languages,
              onChange: changeLanguage
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}
}

})