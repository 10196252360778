// 文件位置：service/tools.ts
import axios from "@/setups/axios-setup";
import config from "@/services/config";
import CryptoJS from "crypto-js";
import {onMounted, onUnmounted, ref, Ref} from 'vue';
import {format} from 'date-fns';
import {ElLoading, ElMessageBox, ElNotification} from "element-plus";


export function generateSecureApiKey(length: number = 32): string {
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('');
}

// 文件大小格式化函数
export function formatFileSize(size: number): string {
    if (size === 0) return '';
    const k = 1024;
    const sizes = ['B', 'K', 'M', 'G', 'T'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return (size / Math.pow(k, i)).toFixed(1) + sizes[i];
}

/**
 * 文本格式化函数
 * @param {string} text - 需要格式化的文本
 * @returns {string} - 返回格式化后的文本，换行符会替换为 <br> 标签
 */
export const formatText = (text: string): string => {
    if (!text) return '';
    // 将换行符替换为 <br> 标签
    return text.replace(/\n/g, '<br>');
}


/**
 * 格式化日期
 * @param { Function} t - 需要多语言对象
 * @param {Date |  string | number | null | undefined} input - 需要格式化的日期对象
 * @param {string} [dateFormat='yyyy-MM-dd HH:mm'] - 日期格式字符串，默认值为 'yyyy-MM-dd HH:mm'
 * @returns {string} - 返回格式化后的日期字符串，如果日期为 null 或 undefined，则返回空字符串
 */
export const formatDate = (
    t: Function,
    input: Date | string | number | null | undefined,
    dateFormat: string = 'yyyy-MM-dd HH:mm'): string => {
    try {
        // 统一转换为有效 Date 对象
        let date: Date | null = null;

        if (input instanceof Date) {
            date = input;
        } else if (typeof input === 'string' || typeof input === 'number') {
            date = new Date(input);
        }

        // 严格验证日期有效性
        if (!date || isNaN(date.getTime())) {
            return '';
        }

        if (date) {
            const now = new Date();
            // 创建当天0点的时间对象用于比较
            const dateStart = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            );
            const nowStart = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );

            const diffTime = nowStart.getTime() - dateStart.getTime();
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            // 判断并生成前缀
            let prefix = '';
            switch (diffDays) {
                case 0:
                    prefix = t('message.today');
                    break;
                case 1:
                    prefix = t('message.yesterday');
                    break;
            }

            // 如果在两天内，返回带前缀的格式
            if (prefix) {
                return `${prefix} ${format(date, 'HH:mm')}`;
            }

            // 其他情况返回原格式
            return format(date, dateFormat);
        }
        return '';
    } catch (error) {
        console.error('格式化日期时发生错误:', error);
        return '';
    }
};

/**
 * 显示加载指示器
 * @param {Function} t - 国际化函数
 * @param {HTMLElement} target - 需要显示加载指示器的目标元素
 * @returns {ReturnType<typeof ElLoading.service>} 加载指示器实例
 */
export const showLoading = (t: Function, target: HTMLElement): ReturnType<typeof ElLoading.service> => {
    return ElLoading.service({
        target: target,
        lock: true,
        text: t('message.loading')
    });
};

/**
 * 检查用户是否已登录
 * @returns {Promise<boolean>} - 返回一个 Promise，表示用户是否已登录
 */
export const checkLogin = async (): Promise<boolean> => {
    try {
        const {useUserStore} = await import('@/stores/userStore');
        const userStore = useUserStore();

        // 如果已经登录且用户信息已经加载，则不再重复请求
        if (userStore.loginUser) {
            return true;
        }
        const response = await axios.post(`${config.apiUserUrl}/check-login`);
        if (response.data.logged_in) {
            const user = await userStore.loadUser(true);
            if (user !== null) {
                userStore.loginUser = user;
            } else {
                userStore.loginUser = null;
            }
        }
        return response.data.logged_in;
    } catch (error) {
        return false;
    }
}


/**
 * 使用 AES-CBC 模式加密数据，并返回 Base64 编码的字符串（带 IV）。
 *
 * @param data - 需要加密的对象或字符串
 * @returns 加密后的字符串，格式为 ENC:<Base64(IV + Cipher)>
 */
export const encryptData = (data: object | string): string => {
    try {
        if (!data) {
            return "";
        }
        // 如果已经是加密格式，直接返回
        if (typeof data === "string" && data.startsWith("ENC:")) {
            return data;
        }

        // 转换数据为字符串
        const dataToEncrypt = typeof data === "string" ? data : JSON.stringify(data);

        // 生成 16 字节随机 IV
        const iv = CryptoJS.lib.WordArray.random(16);

        // 解析加密密钥（确保密钥长度为 16/24/32 字节）
        const key = CryptoJS.enc.Utf8.parse(config.encryptionKey);

        // 使用 AES-CBC 进行加密
        const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: iv
        });

        // Base64 编码 (IV + 密文)
        const encryptedData = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));

        return `ENC:${encryptedData}`;
    } catch (error) {
        console.error("加密数据时发生错误:", error);
        return "";
    }
};


/**
 * 使用 AES-CBC 模式解密数据。
 *
 * @param ciphertext - 以 'ENC:' 开头的加密数据（Base64 编码）
 * @returns 解密后的 JSON 对象或字符串
 */
export const decryptData = (ciphertext: string): any => {
    let decryptedData: string | null = null;

    if (!ciphertext || !ciphertext.startsWith("ENC:")) {
        return null;
    }

    try {
        // 去掉 'ENC:' 前缀
        const encryptedData = ciphertext.slice(4);

        // Base64 解码
        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData);

        // 解析 IV（前 16 字节）
        const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4), 16);

        // 解析实际的密文（去掉 IV 的部分）
        const encryptedMessage = CryptoJS.lib.WordArray.create(
            encryptedBytes.words.slice(4),
            encryptedBytes.sigBytes - 16
        );

        // 解析 AES 密钥
        const key = CryptoJS.enc.Utf8.parse(config.encryptionKey);

        const encryptedMessageBase64 = CryptoJS.enc.Base64.stringify(encryptedMessage);

        // 执行 AES-CBC 解密
        const decryptedBytes = CryptoJS.AES.decrypt(
            encryptedMessageBase64,
            key,
            {
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
                iv: iv,
            }
        );

        decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

        // 尝试解析 JSON
        try {
            return JSON.parse(decryptedData);
        } catch {
            return decryptedData; // 不是 JSON，则直接返回字符串
        }
    } catch (error) {
        console.error("解密数据时发生错误:", error);
        return decryptedData; // 直接返回解密后的字符串（可能未正确解密）
    }
};


/**
 * 获取表格高度的自定义 Hook
 * @param {number} [offset=190] - 表格高度的偏移量，默认值为 190
 * @returns {Ref<number>} - 返回一个引用类型的表格高度
 */
export const autoHeight = (offset: number = 190): Ref<number> => {
    const height: Ref<number> = ref(0);
    let resizeObserver: ResizeObserver | null = null;

    const handleResize = () => {
        requestAnimationFrame(() => {
            const mainContainer = document.querySelector('.el-main') as HTMLElement | null;

            if (mainContainer) {
                height.value = mainContainer.clientHeight - offset;
            }
        });
    };

    onMounted(() => {
        const mainContainer = document.querySelector('.el-main') as HTMLElement | null;

        if (mainContainer) {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(mainContainer);
        }

        // 初始设置高度
        handleResize();
    });

    onUnmounted(() => {
        resizeObserver?.disconnect();
    });

    return height;
};


/**
 * 显示通知
 * @param {'success' | 'warning' | 'info' | 'error'} type - 通知类型
 * @param {string} title - 通知标题
 * @param {string} message - 通知内容
 */
export const showNotification = (type: 'success' | 'warning' | 'info' | 'error', title: string, message: string): void => {
    ElNotification({
        title,
        message,
        type,
    });
}

/**
 * 显示消息框
 * @param {Function} t - 国际化函数，用于消息框标题的翻译
 * @param {string} title - 消息框标题
 * @param {string} message - 消息内容
 * @param {boolean} [isAlert=true] - 是否为警告框，默认为 true
 */
export const showMessageBox = async (t: Function, title: string, message: string, isAlert: boolean = true): Promise<void> => {
    try {
        if (isAlert) {
            await ElMessageBox.alert(message, title, {
                confirmButtonText: t('message.close'),
                type: 'warning',
                center: true,
            });
        } else {
            await ElMessageBox.confirm(message, title, {
                confirmButtonText: t('message.confirm'),
                cancelButtonText: t('message.cancel'),
                type: 'warning',
                center: true,
            });
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('显示消息框时发生错误:', error);
        }
        throw error;
    }
}


export const get_user_label = async (user_id: number) => {
    try {
        const response = await axios.get(`${config.apiSysUrl}/user_label/${user_id}`);
        return response.data;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'InvalidEntityType':
                showNotification('warning', '获取标签关系', "无效关系类型");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
}
