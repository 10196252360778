import {defineStore} from 'pinia';
import {ChatErrorCode, ChatMessage, ChatSession, Model, StreamResponse} from "@/types/LLM";
import {
    chat_stream,
    getAnswer,
    getChatHistory,
    getChatSessionHistory,
    getModels,
    save_chat
} from "@/services/llmService"
import {Prompt} from '@/types/Prompt';


export const useLLMStore = defineStore('llmStore', {
    state: () => ({
        chat_msg: {} as ChatMessage,
        chat_history: [] as ChatMessage[],
        chat_session: [] as ChatSession[],
        streamAnswer: [] as string[],
        streamReasoning: [] as string[],
        streaming: false,
        prompt: {} as Prompt,
        modelsList: [] as Model[],
        modelsTotal: 0,
        abortController: new AbortController()

    }),
    actions: {
        async get_Models(keyword: string | null, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getModels(keyword, page, pageSize);
                if (response != null && 'models' in response) {
                    this.modelsList = response.models
                    this.modelsTotal = response.total;
                    localStorage.setItem("model_page_size", pageSize.toString());
                    return response.total;
                }
                return 0
            } catch (error) {
                console.error('unknown error: ', error);
                return 0
            }
        },

        async sendToLLM(message: ChatMessage[]) {
            this.streaming = false;
            return await getAnswer(message)
        },

        async sendToLLMStreaming(msgs: ChatMessage[]) {
            const abortController = new AbortController(); // 创建一个新的 AbortController 实例
            this.abortController = abortController; // 保存 abortController，用于后续停止
            this.streaming = true;
            let first = true;
            for (let i = 0; i <= 1; i++) {
                this.streamAnswer.push("")
                this.streamReasoning.push("")
            }

            // 初始化Chat_msg
            this.chat_msg.sys_content = msgs[msgs.length - 1].sys_content
            this.chat_msg.robot_content = msgs[msgs.length - 1].robot_content;
            this.chat_msg.topic = msgs[msgs.length - 1].topic;
            this.chat_msg.relevance = true;

            try {
                await chat_stream(msgs, abortController, (message: StreamResponse | Prompt) => {

                    if (first && 'topic' in message) {
                        first = false
                        this.chat_msg.sys_content = message.sys_content;
                        this.chat_msg.robot_content = message.robot_content;
                        this.chat_msg.topic = message.topic;
                        this.chat_msg.relevance = message.relevance;
                        this.chat_msg.source = message.source;
                    } else if ('prompt_id' in message) {
                        return message  //弹出任务框，之后优化
                    } else if ('reasoning' in message || 'content' in message) {
                        if ('reasoning' in message) {
                            if (!this.streamReasoning[msgs.length]) this.streamReasoning[msgs.length] = ""
                            if (message['reasoning'] != '') this.streamReasoning[msgs.length] += message['reasoning']
                        } else {
                            if (message['content'] != '') this.streamAnswer[msgs.length] += message['content']
                        }
                    }
                });

                return null;

            } finally {
                this.streaming = false;
            }
        },

        async saveNowChat(msgs: ChatMessage[]) {
            try {
                return await save_chat(msgs)
            } finally {
                this.streaming = false;
            }
        },

        async loadChatList(fromDate: Date, toDate: Date) {
            try {
                const response = await getChatHistory(fromDate, toDate)

                this.chat_history = response.history
                this.chat_session = response.session
                this.streamAnswer.length = 0
                this.streamReasoning.length = 0
                if (response.history) {
                    for (const item of this.chat_history) {
                        this.streamAnswer.push(item.content ?? '')
                    }
                }

            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.streaming = false;
            }
        },

        async loadChaSessionList(sessionId: string, fromDate: Date | null, toDate: Date | null) {
            try {
                if (!sessionId) return {"error": ChatErrorCode.INVALID_INPUT, "message": "sessionId不能为空"}
                const response = await getChatSessionHistory(sessionId, fromDate, toDate)

                if ('history' in response) {
                    this.chat_history = response.history
                    this.streamReasoning.length = 0
                    this.streamAnswer.length = 0
                    if (response.history) {
                        for (const item of this.chat_history) {
                            this.streamAnswer.push(item.content ?? '')
                            this.streamReasoning.push("")
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.streaming = false;
            }
        }
    }
});