import axios from "@/setups/axios-setup";
import config from "@/services/config";
import {useUserStore} from "@/stores/userStore";

const userStore = useUserStore()

export const dynamic_api_exec = async (api_path: string, api_key: string, command: string, language: string, userid: number) => {
    try {
        if (!language) {
            language = "中文"
        }
        if (!userid) {
            userid = userStore.loginUser?.user_id || -1;
        }

        // 构建请求数据对象
        const data = {
            command: command,
            language: language,
            userid: userid
        };

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `APIKEY-${api_key}`,
        };

        const response = await axios.post(`${config.apiDynamicUrl}` + api_path, data, {headers});
        return response.data;
    } catch (error) {
        console.log("动态API执行出错：\n" + error)
        throw error
    }
};