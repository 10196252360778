<!-- views/Login.vue -->
<template>
  <div style="width: 100%;height: 100%; display: flex;  justify-content: center; align-items: center;">
    <div class="div-wrapper">
      <LoginForm_up></LoginForm_up>
      <!--    <el-tabs  v-model="activeName" tab-position="bottom" stretch class="custom-tab-height">-->
      <!--       <el-tab-pane  name="userPass">-->
      <!--        <template #label>-->
      <!--          <div class="icon-text-button-up">-->
      <!--            <font-awesome-icon icon="user-group" size="2xl" style="color: #256698;margin-bottom: 15px;" />-->
      <!--            <span>{{ t('message.account') }}</span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        -->
      <!--      </el-tab-pane>-->
      <!--&lt;!&ndash;      <el-tab-pane name="wechat">&ndash;&gt;-->
      <!--&lt;!&ndash;        <template #label>&ndash;&gt;-->
      <!--&lt;!&ndash;          <div class="icon-text-button-up">&ndash;&gt;-->
      <!--&lt;!&ndash;            <font-awesome-icon icon="comments" size="2xl" style="color: #1a8968;margin-bottom: 15px;" />&ndash;&gt;-->
      <!--&lt;!&ndash;            <span>{{ t('message.wechat') }}</span>&ndash;&gt;-->
      <!--&lt;!&ndash;          </div>&ndash;&gt;-->
      <!--&lt;!&ndash;        </template>&ndash;&gt;-->
      <!--&lt;!&ndash;        <LoginForm_wechat></LoginForm_wechat>&ndash;&gt;-->
      <!--&lt;!&ndash;      </el-tab-pane>&ndash;&gt;-->

      <!--    </el-tabs>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import LoginForm_up from '@/components/LoginForm-up.vue';
import {ref} from 'vue';
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const activeName = ref('userPass')


</script>

<style scoped>
.div-wrapper {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 580px; /* 占满整个视口高度 */
  width: 300px;

  padding: 50px 50px 15px 50px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  html.dark & {
    background:  rgba(255, 255, 255, 0.5);
  }
}


</style>