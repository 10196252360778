<script setup lang="ts">
import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {Topic} from '@/types/Topic';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useTopicStore} from "@/stores/topicStore";
import NewTopic from "@/components/NewTopic.vue";
import {useI18n} from "vue-i18n";

// 使用国际化功能
const {t} = useI18n();


// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Topic[]>([]);  // 表格数据

const tableHeight = autoHeight(190);  // 表格高度自适应
const userTopic = useTopicStore();  // 使用用户存储
const multipleSelection = ref<Topic[]>([]);  // 多选用户
const userTableRef = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增话题对话框显示
const showEditDialog = ref(false);  // 控制编辑话题对话框显示
const cur_topic = ref<Topic>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData.keyword, currentPage.value, val);
    pageSize.value = val;
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData.keyword, val, pageSize.value);
    currentPage.value = val;
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchTopic = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData, 1, pageSize.value);
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {Topic[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: Topic[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const topicsDelete = async (cur_row: number) => {
  const topicIds: number[] = [];
  try {
    if (!cur_row) {
      return;
    }
    // 检查是否有选中的项目
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.last-one'));
      return;
    }

    // 如果传入的行号有效且不是自己，添加到 topicIds
    if (cur_row !== -1) {
      topicIds.push(cur_row);
    } else {
      topicIds.push(...multipleSelection.value.map(topic => topic.tp_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await userTopic.del_Topics(topicIds);

    tableData.value = tableData.value.filter(item =>
        !topicIds.includes(item.tp_id!)
    );
    totalItems.value = totalItems.value - topicIds.length;
    const count = topicIds.length;
    showNotification("success", t('message.del-success-title'), t('topic.del-topic-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_topic - 是否是新增用户
 * @param {Topic | null} topic_data - 当前用户数据
 */
const show_dialog = async (new_topic: boolean, topic_data: Topic | null) => {
  if (new_topic) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (topic_data) {
      cur_topic.value = topic_data;
    }
  }
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("topic_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});
</script>

<template>
  <el-row>
    <el-col :span="20" class="left-side">
      <div class="pic-text-display">
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                      @keyup.enter="searchTopic(searchData.keyword)"
                      style="width: 350px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchTopic(searchData.keyword)" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
    </el-col>
    <el-col :span="4" class="right-side">
      <el-button-group style="white-space: nowrap">
        <el-button type="primary" @click="show_dialog(true,null)" round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('message.create') }}
        </el-button>
        <el-button type="danger" @click="topicsDelete(-1)" round>
          <font-awesome-icon icon="trash-can" class="icon-text-button"/>
          {{ $t('message.delete') }}
        </el-button>
      </el-button-group>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-divider/>
      <el-table
          ref="userTableRef"
          :data="tableData"
          stripe
          show-overflow-tooltip
          :height="tableHeight"
          @selection-change="handleSelectionChange">
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column prop="name" :label="$t('topic.name')" width="300" sortable>
          <template #default="{ row }">
            <span v-html="row.name"></span>&nbsp;&nbsp;<el-tag type="warning" size="small" effect="plain" round>
            (ID:&nbsp;{{ row.tp_id }})
          </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="description" :label="$t('topic.description')" width="auto"></el-table-column>
        <el-table-column prop="create_datetime" :label="$t('topic.create-date')" width="150" sortable>
          <template #default="{ row }">{{ formatDate(t,row.create_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="modify_datetime" :label="$t('topic.modify-date')" width="150" sortable>
          <template #default="{ row }">{{ formatDate(t,row.modify_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="120" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(false,row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="topicsDelete(row.tp_id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <NewTopic :modelValue="showDialog" @update:isShow="showDialog = $event" @refreshList="handleCurrentChange(1);"/>
      <NewTopic :modelValue="showEditDialog" :topic_info="cur_topic" @update:isShow="showEditDialog = $event"
                @refreshList="handleCurrentChange(1);"/>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next,jumper"
          :total="totalItems"
          @size-change="handleSizeChange(pageSize)"
          @current-change="handleCurrentChange(currentPage)"
      />
    </el-col>
  </el-row>
</template>

<style scoped>

.el-divider {
  background-color: #EBEDF0;
}


.el-row {
  height: auto;
}


</style>