import {defineStore} from 'pinia';
import {API} from '@/types/API';
import {createOrUpdateApi, deleteApis, getApiById, getApis, publishApis} from '@/services/apiService';
import {showNotification} from "@/services/tools";

export const useApiStore = defineStore('apiStore', {
    state: () => ({
        apiList: [] as API[],
        apiTotal: 0,
        api: {} as API,
    }),
    actions: {
        async getAPIs(keyword: string | null, publish: number, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getApis(keyword, publish, page, pageSize);
                this.apiList = response.apis;
                this.apiTotal = response.total;
                localStorage.setItem("api_page_size", pageSize.toString());

                return response.total;

            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'UserNotFound':
                        showNotification('warning', '认证错误', "用户认证出现未知错误，需要重新登录。");
                        break;
                    case 'InvalidInput':
                        showNotification('warning', '查询', "无效的查询参数");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }
                return 0;
            }
        },
        async getAPIById(apiId: number) {
            try {
                this.api = await getApiById(apiId);
                return 0;
            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'MissingAPIIdOrPath':
                        showNotification('error', '查询', "查询参数无效");
                        break;
                    case 'APINotFound':
                        showNotification('error', '查询', "数据丢失");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }

            }
        },
        async createOrUpdateAPI(_api: API) {
            try {
                const response = await createOrUpdateApi(_api);
                return response.success
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async del_APIs(api_ids: number[]) {
            try {
                return await deleteApis(api_ids);
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async publish_APIs(api_ids:number[],station:boolean){
            try {
                return await publishApis(api_ids,station);
            } catch (error) {
                console.log(error)
                throw error
            }
        }
    },
});