// src/services/promptService.ts

import axios from "@/setups/axios-setup";
import {Prompt} from '@/types/Prompt'
import config from '@/services/config'


export const createOrUpdatePrompt = async (prompt: Prompt) => {
    // 使用FormData构建请求数据
    const formData = new FormData();
    formData.append('prompt_data', JSON.stringify(prompt)); // 添加用户数据

    const response = await axios.post(`${config.apiPromptUrl}/prompt`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const publishPrompts = async (ids: number[], station: boolean) => {
    try {

        // 构建请求数据对象
        const data = {
            ids: ids, // 树节点ID
            publish: station // 文件ID列表
        };

        if (ids.length > 0) {
            const response = await axios.post(`${config.apiPromptUrl}/prompts_publish`, data);
            return response.data.success;
        }

    } catch (error) {
        console.log(error)
        throw error
    }
};

export const deletePrompts = async (ids: number[]) => {
    const response = await axios.delete(`${config.apiPromptUrl}/prompt`, {data: ids});
    return response.data;
};

export const getPrompts = async (keyword: string | null, publish: number, page: number, pagesize: number) => {
    // 构建请求数据对象
    const data = {
        search_keyword: keyword, // 搜索关键字
        page: page,                // 当前页码
        page_size: pagesize,       // 每页项目数量
        status: publish            // 发布状态
    };
    const response = await axios.post(`${config.apiPromptUrl}/prompts`, data);
    return response.data;
};

export const getPromptById = async (promptId: number) => {
    const response = await axios.get(`${config.apiPromptUrl}/prompt/${promptId}`);
    return response.data.message;
};

export const getPromptsSummary = async () => {
    const response = await axios.get(`${config.apiPromptUrl}/prompts/summary`);
    return response.data;
};
