<template>
  <el-container>
    <el-header class="title-bg">
      <el-row>
        <el-col :span="16" class="header-left">
          <el-text :span="12" class="header">{{ $t('message.title') }}</el-text>

          <el-tag class="header-sub" effect="light" type="primary" round>
            <font-awesome-icon icon="bars"/>
            {{ curMenuName }}
          </el-tag>
        </el-col>
        <el-col :span="8" class="header-right">
          <el-avatar :src="avatarUrl" class="pic-text-display"></el-avatar>
          <el-text class="pic-text-display" tag="b">{{ username }}</el-text>
          <el-button :type="isDark ? 'info' : 'warning'" @click="toggleDarkMode" circle plain size="small">
            <font-awesome-icon :icon="isDark ? 'moon' : 'sun'"/>
          </el-button>
          <el-button type="primary" plain  @click="logout" round size="small" style="width: auto">
            <font-awesome-icon icon="person-running" class="icon-text-button"/>
            {{ $t('user.logout') }}
          </el-button>
        </el-col>
      </el-row>
    </el-header>
    <el-container>
      <el-aside>
        <SidebarMenu default-active="5-1" @selected_menu_item="changeTitle"/>
      </el-aside>
      <el-main>
        <slot></slot> <!-- 用于插入具体的页面视图 -->
      </el-main>
    </el-container>
    <el-footer class="footer">
      <el-text size="small">{{ $t('message.footer') }}</el-text>
      <div class="language-sel">
        <el-segmented v-model="language" :options="languages" @change="changeLanguage"/>
      </div>
    </el-footer>
  </el-container>
</template>


<script setup lang="ts">
import SidebarMenu from '@/components/SidebarMenu.vue';
import {useUserStore} from '@/stores/userStore';
import {useRouter} from 'vue-router';
import {onBeforeMount, onMounted, ref} from "vue";
import {useThemeStore} from '@/stores/themeStore';
import {useI18n} from 'vue-i18n';
import {useLocaleStore} from '@/stores/localStore';

import {loadSystemOptions} from "@/services/systemService";

const {t} = useI18n();
const {locale: i18nLocale} = useI18n();

const userStore = useUserStore();
const localeStore = useLocaleStore();
const router = useRouter();
const errorMessage = ref('');
const username = ref('');
const avatarUrl = ref('');
const themeStore = useThemeStore();
const isDark = ref(themeStore.isDarkMode);
const curMenuName = ref('')


// 设置响应式的语言状态
const language = ref(t('language.zh'));
const languages = [t('language.zh'), t('language.en'), t('language.ja'), t('language.fr'), t('language.es')]


const toggleDarkMode = () => {
  isDark.value = !isDark.value;
  themeStore.toggleDarkMode();
};

const changeTitle = async (menu_name: string) => {
  curMenuName.value = t("message." + menu_name)
}

const logout = async () => {

  errorMessage.value = await userStore.logout();
  if (errorMessage.value === 'success') {
    await router.push('/login');
  }
}

const getUser = async () => {
  try {
    isDark.value = localStorage.getItem('dark-mode') === 'true';
    const userStore = useUserStore();
    const user = await userStore.loadUser(true);
    username.value = user?.name || '';
    avatarUrl.value = user?.avatar_url || '';
  } catch (error) {
    console.error("Failed to load user:", error);
    username.value = '';
    avatarUrl.value = '';
  }
};

const changeLanguage = () => {
  let temp: ('zh' | 'en' | 'fr' | 'ja' | 'es')

  switch (language.value) {
    case t('language.zh'):
      temp = "zh";
      break
    case t('language.en'):
      temp = "en";
      break
    case t('language.ja'):
      temp = "ja";
      break
    case t('language.fr'):
      temp = "fr"
      break
    case t('language.es'):
      temp = "es"
      break
    default:
      temp = "zh"
  }
  localeStore.setLocale(temp);
  i18nLocale.value = temp;
};

// 执行设置语言操作
onBeforeMount(() => {
  switch (localeStore.getLocale) {
    case "zh":
      language.value = t('language.zh');
      break
    case "en":
      language.value = t('language.en');
      break
    case "ja":
      language.value = t('language.ja');
      break
    case "fr":
      language.value = t('language.fr');
      break
    case "es":
      language.value = t('language.es');
      break
    default:
      language.value = t('language.zh');
  }
  changeLanguage();
});

onMounted(async () => {
  await getUser();
  await loadSystemOptions();
});

</script>

<style lang="scss" scoped>
@import '@/assets/styles/animation-bg.scss';
@import '@/assets/styles/colors.scss';

.header-left{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 20px;
}
.footer {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.header {
  font-size: 30px;
  .text-style {
  font-weight: bold; /* 字体加粗 */
}
  color: $header-font-color-light;
  html.dark & {
    color: $menu-hover-bg;
  }
}

.header-sub {
  font-size: 16px;
  color: $header-font-color-light;
  margin-top: 10px;
  padding: 10px;
  html.dark & {
    color: $menu-hover-bg;
  }
}

.pic-text-display {
  color: $header-font-color-light;

  html.dark & {
    color: $menu-hover-bg;
  }
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}


</style>