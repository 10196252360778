import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = ["innerHTML"]

import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {Topic} from '@/types/Topic';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useTopicStore} from "@/stores/topicStore";
import NewTopic from "@/components/NewTopic.vue";
import {useI18n} from "vue-i18n";

// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'topic_lib_m',
  setup(__props) {

const {t} = useI18n();


// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Topic[]>([]);  // 表格数据

const tableHeight = autoHeight(190);  // 表格高度自适应
const userTopic = useTopicStore();  // 使用用户存储
const multipleSelection = ref<Topic[]>([]);  // 多选用户
const userTableRef = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增话题对话框显示
const showEditDialog = ref(false);  // 控制编辑话题对话框显示
const cur_topic = ref<Topic>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData.keyword, currentPage.value, val);
    pageSize.value = val;
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData.keyword, val, pageSize.value);
    currentPage.value = val;
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchTopic = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userTopic.getTopics(searchData, 1, pageSize.value);
    tableData.value = userTopic.topicList;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {Topic[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: Topic[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const topicsDelete = async (cur_row: number) => {
  const topicIds: number[] = [];
  try {
    if (!cur_row) {
      return;
    }
    // 检查是否有选中的项目
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.last-one'));
      return;
    }

    // 如果传入的行号有效且不是自己，添加到 topicIds
    if (cur_row !== -1) {
      topicIds.push(cur_row);
    } else {
      topicIds.push(...multipleSelection.value.map(topic => topic.tp_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await userTopic.del_Topics(topicIds);

    tableData.value = tableData.value.filter(item =>
        !topicIds.includes(item.tp_id!)
    );
    totalItems.value = totalItems.value - topicIds.length;
    const count = topicIds.length;
    showNotification("success", t('message.del-success-title'), t('topic.del-topic-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_topic - 是否是新增用户
 * @param {Topic | null} topic_data - 当前用户数据
 */
const show_dialog = async (new_topic: boolean, topic_data: Topic | null) => {
  if (new_topic) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (topic_data) {
      cur_topic.value = topic_data;
    }
  }
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("topic_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 20,
          class: "left-side"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_form, {
                ref: "searchForm",
                model: searchData,
                inline: true,
                class: "search-form",
                onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "searchKey",
                    class: "keyword-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: searchData.keyword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchData.keyword) = $event)),
                        placeholder: _ctx.$t('message.search-key'),
                        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (searchTopic(searchData.keyword)), ["enter"])),
                        style: {"width":"350px"},
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (searchTopic(searchData.keyword))),
                    circle: "",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 4,
          class: "right-side"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button_group, { style: {"white-space":"nowrap"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (show_dialog(true,null))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.create')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (topicsDelete(-1))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "trash-can",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_divider),
            _createVNode(_unref(ElTable), {
              ref_key: "userTableRef",
              ref: userTableRef,
              data: tableData.value,
              stripe: "",
              "show-overflow-tooltip": "",
              height: _unref(tableHeight),
              onSelectionChange: handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('topic.name'),
                  width: "300",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", {
                      innerHTML: row.name
                    }, null, 8, _hoisted_2),
                    _cache[14] || (_cache[14] = _createTextVNode("  ")),
                    _createVNode(_component_el_tag, {
                      type: "warning",
                      size: "small",
                      effect: "plain",
                      round: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" (ID: " + _toDisplayString(row.tp_id) + ") ", 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "description",
                  label: _ctx.$t('topic.description'),
                  width: "auto"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "create_datetime",
                  label: _ctx.$t('topic.create-date'),
                  width: "150",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "modify_datetime",
                  label: _ctx.$t('topic.modify-date'),
                  width: "150",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),row.modify_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "120",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(false,row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (topicsDelete(row.tp_id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "height"]),
            _createVNode(NewTopic, {
              modelValue: showDialog.value,
              "onUpdate:isShow": _cache[6] || (_cache[6] = ($event: any) => (showDialog.value = $event)),
              onRefreshList: _cache[7] || (_cache[7] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue"]),
            _createVNode(NewTopic, {
              modelValue: showEditDialog.value,
              topic_info: cur_topic.value,
              "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (showEditDialog.value = $event)),
              onRefreshList: _cache[9] || (_cache[9] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue", "topic_info"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[10] || (_cache[10] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[11] || (_cache[11] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50, 100, 200],
              layout: "total, sizes, prev, pager, next,jumper",
              total: totalItems.value,
              onSizeChange: _cache[12] || (_cache[12] = ($event: any) => (handleSizeChange(pageSize.value))),
              onCurrentChange: _cache[13] || (_cache[13] = ($event: any) => (handleCurrentChange(currentPage.value)))
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})