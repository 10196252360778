//src/services/apiService.ts

import axios from '@/setups/axios-setup'
import {API} from '@/types/API'
import config from '@/services/config'


export const createOrUpdateApi = async (_api: API) => {
    try {
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('api_data', JSON.stringify(_api)); // 添加用户数据

        const response = await axios.post(`${config.apiApiUrl}/api_config`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const deleteApis = async (ids: number[]) => {
    try {
        if (ids.length > 0) {
            const response = await axios.delete(`${config.apiApiUrl}/api_config`, {data: ids});
            return response.data.success;
        }
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const publishApis = async (ids: number[], station: boolean) => {
    try {

        // 构建请求数据对象
        const data = {
            ids: ids, // 树节点ID
            publish: station // 文件ID列表
        };

        if (ids.length > 0) {
            const response = await axios.post(`${config.apiApiUrl}/apis_publish`, data);
            return response.data.success;
        }

    } catch (error) {
        console.log(error)
        throw error
    }
};


export const getApis = async (keyword: string | null, publish: number, page: number, pagesize: number) => {

    // 构建请求数据对象
    const data = {
        search_keyword: keyword, // 搜索关键字
        status: publish,     //发布状态
        page: page,                // 当前页码
        page_size: pagesize       // 每页项目数量
    };

    const response = await axios.post(`${config.apiApiUrl}/apis_config`, data);
    return response.data;

};

export const getApiById = async (api_Id: number) => {
    try {
        const response = await axios.get(`${config.apiApiUrl}/topic/${api_Id}`);
        return response.data.message;
    } catch (error) {
        console.log(error)
        throw error
    }
};
