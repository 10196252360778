<script setup lang="ts">
import {onMounted, reactive, ref, watch} from 'vue';
import {ElTable} from 'element-plus';
import {Robot} from '@/types/Robot';
import {autoHeight, formatDate, formatText, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useRobotStore} from "@/stores/robotStore";
import {useI18n} from "vue-i18n";
import NewRobot from "@/components/NewRobot.vue";

// 使用国际化功能
const {t} = useI18n();
const robotStore = useRobotStore();

// 响应式变量
const currentPage = ref(1); // 当前页码
const pageSize = ref(Number(localStorage.getItem("robot_page_size")) || 20); // 每页显示条数
const totalItems = ref(0); // 总条目数
const tableData = ref<Robot[]>([]); // 表格数据
const publish = ref<string>("-1"); // 发布状态
const tableHeight = autoHeight(190); // 表格高度自适应
const multipleSelection = ref<Robot[]>([]); // 多选机器人
const showDialog = ref(false); // 控制新增对话框
const showEditDialog = ref(false); // 控制编辑对话框
const cur_robot = ref<Robot>(); // 当前操作的机器人
const searchData = reactive({keyword: ''}); // 搜索关键字

/**
 * 获取机器人数据
 */
const fetchRobots = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    totalItems.value = await robotStore.getRobots(searchData.keyword, Number(publish.value), currentPage.value, pageSize.value);
    tableData.value = robotStore.robotList;
  } finally {
    loading.close();
  }
};

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchRobots);

// 处理页码变化
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchRobots();
};

// 处理搜索
const searchRobot = fetchRobots;

// 处理表格行选择变化
const handleSelectionChange = (val: Robot[]) => {
  multipleSelection.value = val;
};

const robotsPublish = async (isPublish: boolean) => {
  if (multipleSelection.value.length === 0) {
    try {
      await showMessageBox(t, t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-11'), t('message.last-one'));
    } catch {
      return;
    }
    return;
  }
  const Ids = multipleSelection.value.map(item => item.rb_id!);
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await robotStore.publish_Robots(Ids, isPublish);
    tableData.value.forEach(item => {
      if (Ids.includes(item.rb_id!)) item.publish = isPublish;
    });
    showNotification("success", t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-11'),
        t(isPublish ? 'message.publish' : 'message.unpublish') + t("robot.robot-success", {count: Ids.length}));
  } catch (error: any) {
    showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
  } finally {
    loading.close();
  }
};

/**
 * 删除选中的机器人
 */
const robotsDelete = async (cur_row: number) => {
  const robotIds = cur_row !== -1 ? [cur_row] : multipleSelection.value.map(robot => robot.rb_id!);
  if (robotIds.length === 0) {
    await showMessageBox(t, t('message.del-title'), t('message.last-one'));
    return;
  }

  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);
    await robotStore.del_Robots(robotIds);
    tableData.value = tableData.value.filter(item => !robotIds.includes(item.rb_id!));
    totalItems.value -= robotIds.length;
    showNotification("success", t('message.del-success-title'),
        t('message.delete') + t('robot.robot-success', {count: robotIds.length}));
  } catch (error: any) {
    if (error != "cancel") {
      showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
    }
  } finally {
    loading.close();
  }
};

/**
 * 显示新增/编辑对话框
 */
const show_dialog = (isNew: boolean, robot_data: Robot | null) => {
  showDialog.value = isNew;
  showEditDialog.value = !isNew;
  cur_robot.value = robot_data || undefined;
};

// 组件挂载后获取数据
onMounted(fetchRobots);
</script>


<template>
  <el-row>
    <el-col :span="18" class="left-side">
      <div class="pic-text-display">
        <el-radio-group v-model="publish" @change="searchRobot">
          <el-radio-button :label="$t('message.all')" value="-1" border/>
          <el-radio-button :label="$t('message.enable')" value="1" border/>
          <el-radio-button :label="$t('message.disable')" value="0" border/>
        </el-radio-group>
        <el-divider direction="vertical" style="height: 25px;"/>
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                      @keyup.enter="searchRobot"
                      style="width: 300px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchRobot" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
    </el-col>
    <el-col :span="6" class="right-side">
      <el-button-group>
        <el-button type="primary" @click="show_dialog(true,null)" round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('message.create') }}
        </el-button>
        <el-button type="primary" @click="robotsPublish(true)" round>
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.publish') }}
        </el-button>
        <el-button type="primary" @click="robotsPublish(false)" round>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.unpublish') }}
        </el-button>
        <el-button type="danger" @click="robotsDelete(-1)" round>
          <font-awesome-icon icon="trash-can" class="icon-text-button"/>
          {{ $t('message.delete') }}
        </el-button>
      </el-button-group>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-divider/>
      <el-table
          ref="robotTable"
          :data="tableData"
          stripe
          show-overflow-tooltip
          :height="tableHeight"
          @selection-change="handleSelectionChange">
        <el-table-column type="expand">
          <template #default="props">
            <div class="gray-background">
              <el-row>
                <el-col :span="12">
                  <div class="div_col">
                    <div v-if="props.row.system_role_content">
                      <el-text class="title_text">{{ $t('robot.system_role_content') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.system_role_content }}</el-text>
                    </div>
                    <div v-if="props.row.create_datetime">
                      <el-text class="title_text">{{ $t('message.create_datetime') }}</el-text>
                      <br>{{ formatDate(t,props.row.create_datetime, 'yyyy-MM-dd HH:mm') }}
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" style="align-content: flex-start">
                  <div class="div_col">
                    <div v-if="props.row.command">
                      <el-text class="title_text">{{ $t('prompt.command') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.command }}</el-text>
                    </div>
                    <div v-if="props.row.output_parser">
                      <el-text class="title_text">{{ $t('prompt.output_parser') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.output_parser }}</el-text>
                    </div>
                  </div>
                </el-col>
                <div v-if="props.row.prompts && props.row.prompts.length > 0" style="width: 100%">
                  <el-divider content-position="left">
                    <div class="title_text">{{ $t('robot.prompts') }}</div>
                  </el-divider>
                  <el-table
                      :data="props.row.prompts"
                      :default-sort="{ prop: 'item_sort', order: 'ascending' }"
                      class="sub-table">
                    <el-table-column :label="$t('prompt.prompt_id')" prop="prompt_id" width="60px" align="center"/>
                    <el-table-column :label="$t('prompt.name')" prop="name" width="170px"
                                     class="multi-line-text">
                      <template #default="scope">
                        <span v-html="formatText(scope.row.name)"></span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('prompt.description')" prop="description"
                                     class="multi-line-text">
                      <template #default="scope">
                        <span v-html="formatText(scope.row.description)"></span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column fixed="left" width="30">
          <template #default="{ row }">
            <span>
              <span
                  v-if="row.publish"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot red"
              ></span>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="name" :label="$t('robot.name')" width="300" sortable>
          <template #default="scope">
            <span v-html="scope.row.name"></span>&nbsp;&nbsp;<el-tag type="warning" size="small" effect="plain" round>
            (ID:&nbsp;{{ scope.row.rb_id }})
          </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="description"
                         :label="$t('robot.description')"
                         class="multi-line-text">
          <template #default="scope">
            <span v-html="formatText(scope.row.description)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="modify_datetime" :label="$t('message.modify_datetime')" width="150" align="center"
                         sortable>
          <template #default="{ row }">{{ formatDate(t,row.modify_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="120" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(false,row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="robotsDelete(row.rb_id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <NewRobot :modelValue="showDialog" @update:isShow="showDialog = $event"
                @refreshList="fetchRobots"/>
      <NewRobot :modelValue="showEditDialog" :robot_info="cur_robot" @update:isShow="showEditDialog = $event"
                @refreshList="fetchRobots"/>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
          @size-change="pageSize = $event"
          @current-change="handlePageChange"
      />
    </el-col>
  </el-row>

</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.sub-table {
  border-radius: 20px;
}


.div_col {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  gap: 10px; /* 设置间距 */
}

.gray-background {
  background-color: $menu-bg; /* 设置为你想要的灰色 */
  padding: 20px; /* 可选：添加一些内边距让内容不贴着边 */
  margin-left: 80px;
  margin-right: 15px;
  border-radius: 20px; /* 添加圆角 */
  html.dark & {
    background-color: $main-background-dark;
  }
}

.el-divider {
  background-color: #EBEDF0;

  html.dark & {
    background-color: $main-background-dark;
  }
}


.el-button {
  width: auto;
}

.el-row {
  height: auto;
}

:deep(.el-divider__text) {
  background-color: $menu-bg !important;
  border-radius: 10px !important;
}

</style>