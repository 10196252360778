import {defineStore} from 'pinia';
import {User} from '@/types/User';
import {
    deleteUsers,
    forgotPassword,
    getUserById,
    getUserList,
    login as apiLogin,
    logout as apiLogout,
    registerUser
} from '@/services/userService'; // 服务层处理API调用
import {decryptData, encryptData} from "@/services/tools";

// 定义用户存储
export const useUserStore = defineStore('userStore', {
    // 状态
    state: () => ({
        loginUser: null as User | null, // 登录用户对象
        user: <User>{},
        users: [] as User[],  // 用户列表
        token: '',  // 认证令牌
    }),

    // 动作
    actions: {
        /**
         * 获取用户列表
         * @param {string} search_key - 搜索关键字
         * @param {number} vip - 会员状态
         * @param {number} page - 当前页码
         * @param {number} pagesize - 每页的用户数量
         * @returns {Promise<number>} 返回用户总数，失败时返回0
         */
        async getUsers(search_key: string, vip: number, page: number, pagesize: number): Promise<number> {
            try {
                // 调用服务层方法获取用户列表
                const response = await getUserList(search_key, vip, page, pagesize);
                if (response != null) {
                    this.users = response.users;
                    localStorage.setItem("user_page_size", pagesize.toString());  // 将页大小存储到本地
                    return response.total;  // 返回用户总数
                }
                return 0;
            } catch (error) {
                // 捕获并处理请求中的任何错误
                console.error('unknown error: ', error);
                throw error
            }
        },

        /**
         * 用户登出
         * @returns {Promise<string>} 成功返回"success"，失败返回"failed"
         */
        async logout(): Promise<string> {
            try {
                // 调用服务层方法进行登出
                const station = await apiLogout();
                if (station) {
                    // 登出成功，清除本地存储和用户状态
                    localStorage.removeItem('user');
                    this.user = {};  // 清除用户状态
                    this.loginUser = null;
                    return "success";
                } else {
                    return "failed";
                }
            } catch (error) {
                // 捕获并处理请求中的任何错误
                console.error('Error during logout:', error);
                return "failed";
            }
        },

        /**
         * 用户登录
         * @param {string} username - 用户名
         * @param {string} password - 密码
         * @returns {Promise<string>} 返回登录状态
         */
        async login(username: string, password: string): Promise<string> {
            try {
                // 调用服务层方法进行登录
                const result = await apiLogin(username, password);

                if (result.success) {
                    // 登录成功，更新用户状态
                    this.user = result.user as User;
                    this.loginUser = this.user
                    localStorage.setItem('user', encryptData(this.user));  // 将加密后的用户信息存储到本地
                }
                return result.station;
            } catch (error) {
                // 捕获并处理请求中的任何错误
                console.error('Unknown error:', error);
                throw error;
            }
        },

        /**
         * 加载用户信息
         * @param {boolean} ifLocal - 是否优先从本地存储中加载用户
         * @returns {Promise<User | null>} 返回User对象或在失败时返回null
         */
        async loadUser(ifLocal: boolean = true): Promise<User | null> {
            try {
                // 尝试从本地存储中获取用户数据
                const userData = localStorage.getItem('user');

                if (userData) {
                    const parsedUser: User = decryptData(userData);  // 解密用户数据

                    // 如果允许从本地存储获取，则直接返回解析后的用户数据
                    if (ifLocal) {
                        return parsedUser as User;
                    }
                    // 否则使用用户ID从服务器重新获取最新的用户信息
                    return await getUserById(parsedUser.user_id ?? 0);
                }

                // 如果本地没有用户数据，则输出错误并返回null
                console.error('No local user data found');
                return null;
            } catch (error: any) {
                // 捕获并处理加载用户信息时发生的错误
                console.error('Error loading user:', error.message);
                return null;
            }
        },

        /**
         * 注册新用户
         * @param {User} user - 用户对象
         * @param {File | null} avatar_pic - 用户头像文件，可以为空
         * @returns {Promise<string>} 返回注册结果状态码
         */
        async register(user: User, avatar_pic: File | null): Promise<string> {
            try {
                // 调用服务层方法进行注册
                const result = await registerUser(user, avatar_pic);
                this.user = user;
                this.user.password = "";
                if (this.loginUser) {
                    if (user.login_name == this.loginUser.login_name) {
                        this.loginUser.avatar_url = result.message
                        this.loginUser.name = user.name
                        this.loginUser.password = "";
                        localStorage.setItem('user', encryptData(this.loginUser));  // 将加密后的用户信息存储到本地
                    }
                }

                return result.success;
            } catch (error) {
                // 捕获并处理注册过程中发生的错误
                console.error('Unknown error:', error);
                throw error;
            }
        },

        /**
         * 重置用户密码
         * @param {number} login_name - 用户登录名
         * @param {string} new_pass - 新密码
         * @returns {Promise<string>} 返回重置密码结果状态码
         */
        async forgotPassword(login_name: string, new_pass: string): Promise<string> {
            try {
                // 调用服务层方法重置密码
                const result = await forgotPassword(login_name, new_pass);
                if (result === "success") {
                    return "0";  // 成功返回"0"
                } else {
                    return "1";  // 失败返回"1"
                }
            } catch (error) {
                // 捕获并处理重置密码过程中发生的错误
                console.error('Unknown error:', error);
                return "2";  // 错误返回"2"
            }
        },

        async del_Users(user_id: number[]): Promise<string> {
            try {
                // 调用服务层方法删除用户
                return await deleteUsers(user_id);
            } catch (error) {
                // 捕获并处理删除用户过程中发生的错误
                console.error('Unknown error:', error);
                throw error;
            }
        }
    }
});
