<script setup lang="ts">
import {useI18n} from "vue-i18n" // 导入国际化插件
import {keys, SystemOption} from "@/types/system";
import {
  autoHeight,
  formatText,
  showLoading,
  showMessageBox,
  showNotification
} from "@/services/tools";
import {ElTable} from "element-plus";

import {onMounted, reactive, ref} from 'vue';
import {Model} from '@/types/LLM'
import {useLLMStore} from "@/stores/llmStore";
import {loadSystemOptions, saveOption, saveSystemOptions} from "@/services/systemService";

// 使用国际化功能
const {t} = useI18n();

// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Model[]>([]);  // 表格数据
const tableHeight = autoHeight(320);  // 表格高度自适应
const modelStore = useLLMStore();  // 使用用户存储
const modelTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const cur_model = ref<Model>();  // 当前选中的模型
const main = ref("")
const small = ref("")
const logic = ref("")
const searchData = reactive({
  keyword: ''  // 搜索关键字
});

// 单独保存不同服务的函数，调用通用保存函数
const save_deepseek = () => saveOption(t, 'deepseek', ['ak', 'url']);
const save_moon = () => saveOption(t, 'moonshot', ['ak', 'url']);
const save_baidu = () => saveOption(t, 'baidu', ['ak', 'sk']);
const save_baichuan = () => saveOption(t, 'baichuan', ['ak', 'url']);
const save_volc = () => saveOption(t, 'volc', ['ak', 'sk']);
const save_openai = () => saveOption(t, 'openai', ['ak', 'url']);

const searchModels = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData, 1, pageSize.value);
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

const save_main = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.main-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['main_llm'] = cur_model.value.supplier
  SystemOption['main_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size
  main.value = SystemOption['main_llm' + cur_model.value.supplier].split(':')[0];
  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.main-llm`), `${t(`chat.main-llm`)} ${t("message.save-options-success")}`);
}

const save_small = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.small-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['small_llm'] = cur_model.value.supplier
  SystemOption['small_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size
  small.value = SystemOption['small_llm' + cur_model.value.supplier].split(':')[0];
  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.small-llm`), `${t(`chat.small-llm`)} ${t("message.save-options-success")}`);
}

const save_logic = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.logic-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['logic_llm'] = cur_model.value.supplier
  SystemOption['logic_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size
  logic.value = SystemOption['logic_llm' + cur_model.value.supplier].split(':')[0];
  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.logic-llm`), `${t(`chat.logic-llm`)} ${t("message.save-options-success")}`);
}

const handleSelectionChange = (val: Model | undefined) => {
  cur_model.value = val;
};


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData.keyword, currentPage.value, val);
    pageSize.value = val;
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData.keyword, val, pageSize.value);
    currentPage.value = val;
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

onMounted(async () => {
  try {
    await loadSystemOptions();
    main.value = SystemOption['main_llm' + SystemOption['main_llm']].split(':')[0];
    small.value = SystemOption['small_llm' + SystemOption['small_llm']].split(':')[0];
    logic.value = SystemOption['logic_llm' + SystemOption['logic_llm']].split(':')[0];

    // 初始化密钥值
    Object.keys(keys).forEach(key => {
      const typedKey = key as keyof typeof keys;
      keys[typedKey].value = SystemOption[typedKey];
    });

    const ps_key = localStorage.getItem("model_page_size");
    if (ps_key) {
      pageSize.value = Number(ps_key);
    }
    await handleCurrentChange(1);  // 初始化加载第一页数据
  } catch (error) {
    console.error('初始化失败:', error);
  }
});
</script>


<template>
  <el-row :gutter="20">
    <el-col :span="14">
      <el-row class="top-row">
        <div class="pic-text-display">
          <el-form ref="searchForm"
                   :model="searchData"
                   :inline="true"
                   class="search-form"
                   @submit.prevent
          >
            <el-form-item prop="searchKey" class="keyword-input">
              <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                        @keyup.enter="searchModels(searchData.keyword)"
                        style="width: 200px;"
                        clearable></el-input>
            </el-form-item>
            <el-button type="primary" @click="searchModels(searchData.keyword)" circle plain>
              <template #default>
                <font-awesome-icon icon="magnifying-glass"/>
              </template>
            </el-button>
          </el-form>
        </div>
        <div>
          <el-button type="primary" size="large" @click="save_main" round>{{ $t('chat.set-main-llm') }}</el-button>
          <el-button type="success" size="large" @click="save_small" round>{{ $t('chat.set-small-llm') }}</el-button>
          <el-button type="warning" size="large" @click="save_logic" round>{{ $t('chat.set-logic-llm') }}</el-button>
        </div>
      </el-row>
      <el-divider/>
      <el-row>
        <el-table
            ref="modelTable"
            :data="tableData"
            highlight-current-row
            stripe
            :height="tableHeight"
            @current-change="handleSelectionChange">
          <el-table-column prop="supplier" :label="$t('chat.model-supplier')" width="100" sortable
                           align="center"></el-table-column>
          <el-table-column width="60" align="center">
            <template #default="{ row }">
              <div v-if="row.free" class="free-label">FREE</div>
              <div v-if="row.model+':'+row.size===SystemOption['main_llm'+SystemOption['main_llm']]" class="main-label">
                MAIN
              </div>
              <div v-if="row.model+':'+row.size===SystemOption['small_llm'+SystemOption['small_llm']]"
                   class="small-label">SMALL
              </div>
              <div v-if="row.model+':'+row.size===SystemOption['logic_llm'+SystemOption['logic_llm']]"
                   class="logic-label">LOGIC
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('chat.model-name')" width="180" sortable></el-table-column>
          <el-table-column prop="size" :label="$t('chat.model-size')" width="80" align="center"
                           sortable></el-table-column>
          <el-table-column prop="desc"
                           :label="$t('chat.model-desc')"
                           class="multi-line-text">
            <template #default="scope">
              <span v-html="formatText(scope.row.desc)"></span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalItems"
            @size-change="handleSizeChange(pageSize)"
            @current-change="handleCurrentChange(currentPage)"
        />
      </el-row>
      <el-row>
        <el-divider>
          <div class="title_text">{{ $t('chat.cur-model') }}</div>
        </el-divider>
        <el-row>
          <el-col :span="7" class="display-card">
            <el-text tag="b" size="large" type="primary">{{ $t('chat.main-llm') }}</el-text>
            <el-text>{{ main }}</el-text>
          </el-col>
          <el-col :span="7" class="display-card">
            <el-text tag="b" size="large" type="success">{{ $t('chat.small-llm') }}</el-text>
            <el-text>{{ small }}</el-text>
          </el-col>
          <el-col :span="7" class="display-card">
            <el-text tag="b" size="large" type="warning">{{ $t('chat.logic-llm') }}</el-text>
            <el-text>{{ logic }}</el-text>
          </el-col>
        </el-row>
      </el-row>
    </el-col>
    <el-col :span="5">
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.deepseek") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-ak") }}</span>
          <el-input
              v-model=keys.deepseek_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-url") }}</span>
          <el-input
              v-model=keys.deepseek_url.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_deepseek"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.baidu") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.baidu-ak") }}</span>
          <el-input
              v-model=keys.baidu_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.baidu-sk") }}</span>
          <el-input
              v-model=keys.baidu_sk.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_baidu"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.baichuan") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-ak") }}</span>
          <el-input
              v-model=keys.baichuan_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-url") }}Y</span>
          <el-input
              v-model=keys.baichuan_url.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_baichuan"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.moonshot") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-ak") }}</span>
          <el-input
              v-model=keys.moonshot_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-url") }}</span>
          <el-input
              v-model=keys.moonshot_url.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_moon"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.volc") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.volc-ak") }}</span>
          <el-input
              v-model=keys.volc_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.volc-sk") }}</span>
          <el-input
              v-model=keys.volc_sk.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_volc"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
      <el-card shadow="hover" class="system-card">
        <template #header>
          <div class="card-header">
            {{ $t("chat.openai") }}
          </div>
        </template>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-ak") }}</span>
          <el-input
              v-model=keys.openai_ak.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>
        <div class="system-content">
          <span class="system-label">{{ $t("chat.model-url") }}</span>
          <el-input
              v-model=keys.openai_url.value
              type="password"
              show-password
              maxlength="200"
              clearable
          >
          </el-input>
        </div>

        <template #footer>
          <el-button
              type="primary"
              round
              @click="save_openai"
          >
            <font-awesome-icon icon="floppy-disk" class="icon-text-button"/>
            {{ $t("message.save") }}
          </el-button>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.el-divider {
  background-color: #EBEDF0;

  html.dark & {
    background-color: $main-background-dark;
  }
}

:deep(.el-divider__text) {
  background-color: $menu-bg !important;
  border-radius: 10px !important;
  padding: 1px 15px 2px 15px;
}

.el-card :deep(.el-card__header) {
  background-color: $menu-hover-bg;
  border: 0;
  padding: 10px 10px 10px 20px;

  html.dark & {
    background-color: $menu-sub-dark-bg
  }
}

.el-card :deep(.el-card__footer) {
  border: 0;
  justify-content: flex-end;
  display: flex;
  padding: 0 15px 15px 0;
}


.system-content {
  padding: 0;
}

.system-label {
  font-size: 14px;
  line-height: 25px;
}


.avatar-uploader-icon {
  color: $header-font-color-light;
  width: 50px;
  height: 50px;
  text-align: center;

  html.dark & {
    color: $menu-sub-dark-bg;
  }
}

.avatar-uploader-icon:hover {
  color: $menu-hover-bg;

  html.dark:hover & {
    color: $header-font-color-light;
  }
}


.preview_pic {
  width: 360px;
  height: auto; /* 高度自适应 */
  display: block;
  margin: 10px auto; /* 图片居中 */
  border-radius: 20px;
}

.avatar-preview {
  height: 148px;
  width: 148px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}


.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 隐藏超出容器的部分 */
  border: 0;
  margin-right: 5px;
}

.el-button {
  width: auto;
}

.el-row {
  height: auto;
}


.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.free-label {
  margin-bottom: 3px;
  margin-top: 3px;
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border: 2px solid $highlight-color;
  background-color: #faecd8;

  html.dark & {
    background-color: $notification-dark-bg;
  }
}

.main-label {
  margin-bottom: 3px;
  margin-top: 3px;
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border: 2px solid $label-main-color;
  background-color: $label-main-color;

  html.dark & {
    background-color: $header-font-color-light;
  }
}

.small-label {
  margin-bottom: 3px;
  margin-top: 3px;
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border: 2px solid $switch_on_color;
  background-color: $switch_on_color;

  html.dark & {
    background-color: $switch_on_dark_color;
  }
}

.logic-label {
  margin-bottom: 3px;
  margin-top: 3px;
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  border: 2px solid $popper-dark-bg;
  background-color: $popper-dark-bg;

  html.dark & {
    background-color: $label-dark-color;
  }
}

.el-table {
  --el-table-current-row-bg-color: #faecd8;

  html.dark & {
    --el-table-current-row-bg-color: #b88230;
  }
}

.display-card {
  border-radius: 20px;
  background: $chat-pop;
  margin: 0 8px 0 8px;
  padding: 10px 20px 10px 20px;
  overflow: hidden; /* 内容超出时隐藏 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 30px;

  html.dark & {
    background: $footer-font-color-light;
  }
}


</style>