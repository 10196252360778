import {defineStore} from 'pinia';
import {Robot} from '@/types/Robot';
import {
    createOrUpdateRobot,
    deleteRobots,
    getRobotById,
    getRobots,
    getRobotsSummary,
    publishRobots
} from '@/services/robotService';
import {showNotification} from "@/services/tools";

export const useRobotStore = defineStore('robotStore', {
    state: () => ({
        robotList: [] as Robot[],
        robotSummary: [] as any[],
        robotTotal: 0,
        robot: {} as Robot,
    }),
    actions: {
        async getRobots(keyword: string | null, publish: number, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getRobots(keyword, publish, page, pageSize);
                this.robotList = response.robots;
                this.robotTotal = response.total;
                localStorage.setItem("robot_page_size", pageSize.toString());

                return response.total;

            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'UserNotFound':
                        showNotification('warning', '认证错误', "用户认证出现未知错误，需要重新登录。");
                        break;
                    case 'InvalidInput':
                        showNotification('warning', '查询', "无效的查询参数");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }
                return 0;
            }
        },
        async getRobotById(robotId: number) {
            try {
                this.robot = await getRobotById(robotId);
                return 0;
            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'InvalidInput':
                        showNotification('error', '查询', "查询参数无效");
                        break;
                    case 'NoData':
                        showNotification('error', '查询', "无数据");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }

            }
        },
        async createOrUpdateRobot(robot: Robot) {
            try {
                const response = await createOrUpdateRobot(robot);
                return response.success
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async del_Robots(robot_ids: number[]) {
            try {
                return await deleteRobots(robot_ids);
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async publish_Robots(robot_ids: number[], station: boolean) {
            try {
                return await publishRobots(robot_ids, station);
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async getRobotsSummary() {
            try {
                this.robotSummary = await getRobotsSummary();
            } catch (error) {
                console.error('unknown error: ', error);
            }
        },
    },
});