<script setup lang="ts">
import {computed, onMounted, reactive, ref, watch,onBeforeUnmount } from 'vue';
import {ElScrollbar, ElTable} from 'element-plus';
import {API} from "@/types/API";
import {autoHeight, formatDate, formatText, showLoading, showMessageBox, showNotification} from "@/services/tools";
import {useI18n} from "vue-i18n";
import {useApiStore} from "@/stores/apiStore";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import 'highlight.js/styles/github.css';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import {useClipboard} from '@/services/clipboard';
import {dynamic_api_exec} from "@/services/dynamicApi"
import Clipboard from 'clipboard'

const {t} = useI18n();
const apiStore = useApiStore();

// 解构时明确类型
const {
  initClipboard,
  updateButtonText,
  resetButtonAfterDelay
} = useClipboard()

// 响应式状态
const showChatBack = ref(false)
const currentPage = ref(1);
const pageSize = ref(20);
const totalItems = ref(0);
const tableData = ref<API[]>([]);
const tableHeight = autoHeight(590);
const chatHeight = autoHeight(400);
const codeHeight = autoHeight(162);
const apiTable = ref<InstanceType<typeof ElTable> | null>(null);
const command = ref("");
const language = ref("");
const userid = ref<number>();
const cur_api = ref<API>();
const searchData = reactive({keyword: ''});
const code_py = ref("")
const code_ja = ref("")
const code_ts = ref("")
const code_js = ref("")
const ai_answer = ref("")
const ai_reasoning = ref("")
const b_time = ref("")
const e_time = ref("")
const spend_second = ref(0.0)
const ai_loading = ref(false)




// 配置MarkdownIt用于高亮代码块
const md: MarkdownIt = new MarkdownIt({
  highlight: function (str, lang) {
    // 预处理：转换所有需要的字符到HTML实体
    const safeStr = str
        .replace(/&/g, '&#38;')
        .replace(/</g, '&#60;')
        .replace(/>/g, '&#62;')
        .replace(/"/g, '&#34;')
        .replace(/'/g, '&#39;')
        .replace(/\n/g, '&#10;');

    // 生成代码块的公共头部HTML
    const headerHtml = `<div class="code-header">
      <span class="code-lang">${lang || 'text'}</span>
      <button class="copy-button"
      :data-clipboard-text="safeStr"
      :data-original-text="t('robot.copy-code')">` + t('robot.copy-code') + `</button>
      </div>`;

    let codeHtml;

    // 如果指定了语言且支持，则高亮代码
    if (lang && hljs.getLanguage(lang)) {
      try {
        codeHtml = hljs.highlight(str, {language: lang}).value;
      } catch (error) {
        console.error('代码高亮失败:', error);
        codeHtml = md.utils.escapeHtml(str);
      }
    } else {
      codeHtml = md.utils.escapeHtml(str);
    }

    // 返回完整的代码块HTML
    return `${headerHtml}<div class="code-card"><pre class="hljs"><code>${codeHtml}</code></pre></div>`;
  }
});

const ini_code = async () => {
  code_py.value = `
\`\`\`Python
request_body = {
    "command": "${command.value}",
    "language": "${language.value}",
    "userid": ${userid.value}
}
api_url = '${window.location.origin}/dynamic/api${cur_api.value?.api_path}'
headers = {
    'Content-Type': 'application/json',
    Authorization: 'APIKEY-${cur_api.value?.api_key}',
}
response = requests.post(api_url, headers=headers, data=json.dumps(request_body))

print(response.status_code)
print(response.json())
\`\`\`
`
  code_ts.value = `
\`\`\`TypeScript
interface DynamicApiRequest {
  command: string;
  language: string;
  userid: number;
}
const requestBody: DynamicApiRequest = {
  command: "${command.value}",
  language: "${language.value}",
  userid: ${userid.value}
};

const apiUrl ='${window.location.origin}/dynamic/api${cur_api.value?.api_path}';
const response = await fetch(apiUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'APIKEY-${cur_api.value?.api_key}',
  },
  body: JSON.stringify(requestBody),
});
const data = await response.json();
console.log("Response Data:", data);
\`\`\`
`
  code_ja.value = `
\`\`\`Java
public class DynamicApiRequest {
    public static void main(String[] args) throws Exception {
        JSONObject requestBody = new JSONObject();
        requestBody.put("command", "${command.value}");
        requestBody.put("language", "${language.value}");
        requestBody.put("userid", ${userid.value});
        String apiUrl = "${window.location.origin}/dynamic/api${cur_api.value?.api_path}";
        String apiKey = "APIKEY-${cur_api.value?.api_key}";

        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create(apiUrl))
            .header("Content-Type", "application/json")
            .header("Authorization", apiKey)
            .POST(HttpRequest.BodyPublishers.ofString(requestBody.toString()))
            .build();
        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

        System.out.println("Response Status Code: " + response.statusCode());
        System.out.println("Response Body: " + response.body());
    }
}
\`\`\`
`
  code_js.value = `
\`\`\`JavaScript
const requestBody = {
  command: "${command.value}",
  language: "${language.value}",
  userid: ${userid.value}
};

const apiUrl = "${window.location.origin}/dynamic/api${cur_api.value?.api_path}";
const headers = {
  "Content-Type": "application/json",
  Authorization: "APIKEY-${cur_api.value?.api_key}"
};
fetch(apiUrl, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(requestBody)
})
  .then(response => response.json())
  .then(data => {
    console.log("Response Data:", data);
  })
  .catch(error => {
    console.error("Error:", error);
  });
\`\`\`
`
}

// Markdown 渲染函数
const renderedCode = (content: string) => {
  const formattedContent = content.replace(/\n/g, '  \n');
  return md.render(formattedContent);
};

// 包装数学公式
const wrapMathEquations = (content: string): string => {
  if (!content) return content
  // 处理行内公式
  content = content.replace(/\\\((.*?)\\\)/g, (match) => `$${match.slice(2, -2).trim()}$`);
  // 处理块级公式
  content = content.replace(/\\\[[^\]]+\\]/g, (match) => `$$${match.slice(2, -2).trim()}$$`);

  return content;
};

// 计算 JSON 数据
const jsonData = computed(() => ({
  command: command.value,
  language: language.value,
  userid: userid.value === undefined ? "" : userid.value
}));

// 格式化 JSON
const formattedJson = computed(() => JSON.stringify(jsonData.value, null, 4));

// 计算属性优化数据请求
const fetchAPIs = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    totalItems.value = await apiStore.getAPIs(searchData.keyword, 1, currentPage.value, pageSize.value);
    tableData.value = apiStore.apiList;
  } finally {
    loading.close();
  }
};

// 处理搜索
const searchAPI = fetchAPIs;

// 处理分页变更
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchAPIs();
};

const handleSelectionChange = async (val: API | undefined) => {
  cur_api.value = val;
  await ini_code()
};

// 复制到剪贴板
const copyToClipboard = async (copyText: string, tips: string) => {
  try {
    await navigator.clipboard.writeText(copyText);
    showNotification("success", t("message.copy"), tips + t("message.copy-success"));

  } catch (err) {
    showNotification("error", "复制失败", "无法复制到剪贴板");
  }
};

const api_test = async () => {
  if (!command.value || !userid.value || !cur_api.value) {
    try {
      await showMessageBox(t, t("apiconfig.api-test"), t("apiconfig.invalid-input"), true)
      return
    } catch {
      return
    }
  }
  // 记录开始时间
  const startTime = new Date();

  ai_loading.value = true
  try {
    b_time.value = formatDate(t,new Date(), "HH:mm:ss")
    e_time.value = ""
    ai_answer.value = ""
    ai_reasoning.value = ""
    showChatBack.value = true

    const response = await dynamic_api_exec(
        cur_api.value.api_path,
        cur_api.value.api_key,
        command.value,
        language.value,
        userid.value)

    ai_answer.value = response.content
    ai_reasoning.value = response.reasoning_content

  } catch (error: any) {
    ai_answer.value = error.message
  } finally {
    e_time.value = formatDate(t,new Date(), "HH:mm:ss")
    // 记录结束时间
    const endTime = new Date();
    // 计算时间差（毫秒）
    spend_second.value = (endTime.getTime() - startTime.getTime()) / 1000;
    ai_loading.value = false
  }
}

const openChatBackDialog = async () => {

}

const closeChatBackDialog = async () => {
  ai_answer.value = ""
  showChatBack.value = false
}

// 成功处理逻辑
const handleCopySuccess = (e: Clipboard.Event) => {
  const button = e.trigger as HTMLElement
  const originalText = button.dataset.originalText || button.textContent || ''

  updateButtonText(button, '✓ 复制成功')
  resetButtonAfterDelay(button, originalText)
}

// 错误处理（可选）
const handleCopyError = (e: Clipboard.Event) => {
  console.error('复制失败:', e.action)
}

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchAPIs);
watch([command, language, userid], ini_code)

// 组件挂载后加载数据
onMounted(() => {
  // new Clipboard('.copy-button');
  initClipboard({
    selector: '.copy-button',
    successCallback: (e) => {
      const button = e.trigger as HTMLElement
      button.textContent = '✓ ' + t('robot.copy-success')
      setTimeout(() => {
        button.textContent = t('robot.copy-code')
      }, 1000)
    }
  })

  const ps_key = localStorage.getItem("api_page_size");

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  Promise.all([fetchAPIs(), ini_code()])
});

</script>

<template>
  <el-row :gutter="30">
    <el-col :span="10">
      <div style="width:100%; display: flex; justify-content: right;">
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form" style="margin-bottom: 15px;"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                      @keyup.enter="searchAPI"
                      style="width: 400px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchAPI" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
      <el-table
          ref="apiTable"
          :data="tableData"
          row-key="id"
          stripe
          highlight-current-row
          show-overflow-tooltip
          :height="tableHeight"
          @current-change="handleSelectionChange">
        >
        <el-table-column prop="name" :label="$t('apiconfig.name')" sortable>
          <template #default="scope">
            <span v-html="scope.row.name"></span>
          </template>
        </el-table-column>
        <el-table-column prop="api_path"
                         :label="$t('apiconfig.api_path')"
                         width="100"
                         class="multi-line-text">
          <template #default="scope">
            <span v-html="formatText(scope.row.api_path)"></span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="280" align="center">
          <template #default="scope">
            <el-button type="primary" size="small" round plain
                       @click="copyToClipboard(scope.row.api_path,t('apiconfig.api_path'))"
                       style="margin-left: 10px">
              <font-awesome-icon :icon="faCopy" class="icon-text-button"/>
              <el-text size="small">{{ $t('message.copy') + $t('apiconfig.api_path') }}</el-text>
            </el-button>
            <el-button type="primary" size="small" round plain
                       @click="copyToClipboard(scope.row.api_key,t('apiconfig.api_key'))"
                       style="margin-left: 10px">
              <font-awesome-icon :icon="faCopy" class="icon-text-button"/>
              <el-text size="small">{{ $t('message.copy') + $t('apiconfig.api_key') }}</el-text>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
          @size-change="pageSize = $event"
          @current-change="handlePageChange"
      />
      <el-divider/>
      <el-row :gutter="10" style="height: 400px" class="display-card">
        <el-text
            size="large"
            tag="b"
            class="input-text"
            style="width: 100%;text-align: left">
          {{ t("apiconfig.api-data") }}
        </el-text>
        <el-input
            v-model="command"
            :rows="5"
            :placeholder="t('apiconfig.command')"
            type="textarea"
            resize="none"
            class="input-text"
        />
        <div style="display: flex;justify-content: space-between;width: 100%;gap: 15px;">
          <el-input
              v-model="language"
              :placeholder="t('apiconfig.language')"
              type="text"
              class="input-text"
          />
          <el-input
              v-model="userid"
              :placeholder="t('apiconfig.userid')"
              type="number"
              class="input-text"
          />
        </div>

        <div class="api-data">
          <div class="code-header">
            <span class="code-lang">JSON</span>
          </div>
          <div class="code-card" style="background: white">
            <pre class="hljs"><code>{{ formattedJson }}</code></pre>
          </div>
        </div>

      </el-row>
    </el-col>
    <el-col :span="14">
      <el-row style="height: 35px;">
        <el-col :span="12" class="left-side">
          <el-text
              size="large"
              tag="b"
              class="input-text"
          >
            {{ t("apiconfig.api-code") }}
          </el-text>
        </el-col>
        <el-col :span="12" class="right-side">
          <el-button
              type="primary"
              round
              @click="api_test"
          >{{ t("apiconfig.api-test") }}
          </el-button>
        </el-col>
      </el-row>

      <el-tabs type="border-card" style="margin-top: 15px">
        <el-tab-pane>
          <template #label>
            <div>
              Python
            </div>
          </template>
          <el-scrollbar class="chat-content" :height="codeHeight">
            <div class="api-data">
              <div class="markdown-body" v-html="renderedCode(code_py)"></div>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <div>
              Java
            </div>
          </template>
          <el-scrollbar class="chat-content" :height="codeHeight">
            <div class="api-data">
              <div class="markdown-body" v-html="renderedCode(code_ja)"></div>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <div>
              TypeScript
            </div>
          </template>
          <el-scrollbar class="chat-content" :height="codeHeight">
            <div class="api-data">
              <div class="markdown-body" v-html="renderedCode(code_ts)"></div>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <div>
              JavaScript
            </div>
          </template>
          <el-scrollbar class="chat-content" :height="codeHeight">
            <div class="api-data">
              <div class="markdown-body" v-html="renderedCode(code_js)"></div>
            </div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
          v-model="showChatBack"
          width="1200px"
          style="min-width: 1200px"
          @open="openChatBackDialog"
          append-to-body center draggable align-center :close-on-click-modal="false" :show-close="false">
        <template #header="">
          <div class="dialog-header">
            <span>{{ $t('apiconfig.test-result') }}</span>
            <el-button type="danger" @click="closeChatBackDialog" circle size="small">
              <font-awesome-icon icon="xmark"/>
            </el-button>
          </div>
        </template>
        <el-row>
          <el-tag type="primary" size="large" effect="dark" round style="margin-right: 20px;font-size: 16px;">
            {{ t("apiconfig.begin") }}{{ b_time }}
          </el-tag>
          <el-tag type="primary" size="large" effect="dark" round style="margin-right: 20px;font-size: 16px;">
            {{ t("apiconfig.end") }}{{ e_time }}
          </el-tag>
          <el-tag type="warning" size="large" effect="dark" round style="font-size: 16px;">
            {{ t("apiconfig.spend", {spend_second}) }}
          </el-tag>
        </el-row>
        <el-row>
          <el-tabs style="width: 95%;">
            <el-tab-pane>
              <template #label>
                <div>
                  {{ t("chat.answer") }}
                </div>
              </template>
              <div class="api-data">
                <el-scrollbar v-loading="ai_loading" class="chat-content" :height="chatHeight">
                  <div v-show="ai_answer" class="message-ai chat-window" style="padding: 15px">
                    <div class="markdown-body" v-html="renderedCode(wrapMathEquations(ai_answer))"></div>
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <template #label>
                <div>
                  {{ t("chat.reason") }}
                </div>
              </template>
              <div class="api-data">
                <el-scrollbar v-loading="ai_loading" class="chat-content" :height="chatHeight">
                  <div v-show="ai_reasoning" class="message-ai chat-window" style="padding: 15px">
                    <div class="markdown-body" v-html="renderedCode(wrapMathEquations(ai_reasoning))"></div>
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-row>
        <template #footer>
          <div>
            <el-button type="danger" @click="closeChatBackDialog" plain>
              <font-awesome-icon icon="xmark" class="icon-text-button"/>
              {{ $t('message.close') }}
            </el-button>
          </div>
        </template>
      </el-dialog>

    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.chat-window {
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 15px;
  padding: 0 20px 0 20px;
  margin: 20px;
  height: 100%; /* 确保窗口占据整个视口的高度 */
  background-color: $input-background-light;

  html.dark & {
    background-color: $chat-ai-dark; /* 深色模式接收消息背景色 */
  }
}

.chat-content {
  padding-right: 15px;
  padding-left: 15px;

  html.dark & {
    background-color: $main-background-dark; /* 深色模式背景色 */
  }
}

.el-table {
  --el-table-current-row-bg-color: #faecd8;

  html.dark & {
    --el-table-current-row-bg-color: #b88230;
  }
}

.display-card {
  width: auto;
  border-radius: 15px;
  background: $table-header-bg-color;
  margin-top: 15px;
  padding: 5px 20px 20px 20px;

  html.dark & {
    background: $footer-background-dark;
  }
}

.api-data {
  width: 95%;
  padding: 15px;
}

.input-text {
  margin: 15px 0 0 0;
}

</style>