import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = { class: "gray-background" }
const _hoisted_3 = { class: "div_col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "div_col" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_10 = { class: "title_text" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "status-dot green"
}
const _hoisted_14 = {
  key: 1,
  class: "status-dot red"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]

import {onMounted, reactive, ref, watch} from 'vue';
import {ElTable} from 'element-plus';
import {Robot} from '@/types/Robot';
import {autoHeight, formatDate, formatText, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useRobotStore} from "@/stores/robotStore";
import {useI18n} from "vue-i18n";
import NewRobot from "@/components/NewRobot.vue";

// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'robot_lib_m',
  setup(__props) {

const {t} = useI18n();
const robotStore = useRobotStore();

// 响应式变量
const currentPage = ref(1); // 当前页码
const pageSize = ref(Number(localStorage.getItem("robot_page_size")) || 20); // 每页显示条数
const totalItems = ref(0); // 总条目数
const tableData = ref<Robot[]>([]); // 表格数据
const publish = ref<string>("-1"); // 发布状态
const tableHeight = autoHeight(190); // 表格高度自适应
const multipleSelection = ref<Robot[]>([]); // 多选机器人
const showDialog = ref(false); // 控制新增对话框
const showEditDialog = ref(false); // 控制编辑对话框
const cur_robot = ref<Robot>(); // 当前操作的机器人
const searchData = reactive({keyword: ''}); // 搜索关键字

/**
 * 获取机器人数据
 */
const fetchRobots = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    totalItems.value = await robotStore.getRobots(searchData.keyword, Number(publish.value), currentPage.value, pageSize.value);
    tableData.value = robotStore.robotList;
  } finally {
    loading.close();
  }
};

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchRobots);

// 处理页码变化
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchRobots();
};

// 处理搜索
const searchRobot = fetchRobots;

// 处理表格行选择变化
const handleSelectionChange = (val: Robot[]) => {
  multipleSelection.value = val;
};

const robotsPublish = async (isPublish: boolean) => {
  if (multipleSelection.value.length === 0) {
    try {
      await showMessageBox(t, t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-11'), t('message.last-one'));
    } catch {
      return;
    }
    return;
  }
  const Ids = multipleSelection.value.map(item => item.rb_id!);
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await robotStore.publish_Robots(Ids, isPublish);
    tableData.value.forEach(item => {
      if (Ids.includes(item.rb_id!)) item.publish = isPublish;
    });
    showNotification("success", t(isPublish ? 'message.publish' : 'message.unpublish') + t('message.menu-11'),
        t(isPublish ? 'message.publish' : 'message.unpublish') + t("robot.robot-success", {count: Ids.length}));
  } catch (error: any) {
    showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
  } finally {
    loading.close();
  }
};

/**
 * 删除选中的机器人
 */
const robotsDelete = async (cur_row: number) => {
  const robotIds = cur_row !== -1 ? [cur_row] : multipleSelection.value.map(robot => robot.rb_id!);
  if (robotIds.length === 0) {
    await showMessageBox(t, t('message.del-title'), t('message.last-one'));
    return;
  }

  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);
    await robotStore.del_Robots(robotIds);
    tableData.value = tableData.value.filter(item => !robotIds.includes(item.rb_id!));
    totalItems.value -= robotIds.length;
    showNotification("success", t('message.del-success-title'),
        t('message.delete') + t('robot.robot-success', {count: robotIds.length}));
  } catch (error: any) {
    if (error != "cancel") {
      showNotification("error", t('error.title'), error.response?.data?.detail || '未知错误');
    }
  } finally {
    loading.close();
  }
};

/**
 * 显示新增/编辑对话框
 */
const show_dialog = (isNew: boolean, robot_data: Robot | null) => {
  showDialog.value = isNew;
  showEditDialog.value = !isNew;
  cur_robot.value = robot_data || undefined;
};

// 组件挂载后获取数据
onMounted(fetchRobots);

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 18,
          class: "left-side"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_radio_group, {
                modelValue: publish.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((publish).value = $event)),
                onChange: _unref(searchRobot)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.all'),
                    value: "-1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.enable'),
                    value: "1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.disable'),
                    value: "0",
                    border: ""
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"]),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                style: {"height":"25px"}
              }),
              _createVNode(_component_el_form, {
                ref: "searchForm",
                model: searchData,
                inline: true,
                class: "search-form",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "searchKey",
                    class: "keyword-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: searchData.keyword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchData.keyword) = $event)),
                        placeholder: _ctx.$t('message.search-key'),
                        onKeyup: _withKeys(_unref(searchRobot), ["enter"]),
                        style: {"width":"300px"},
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _unref(searchRobot),
                    circle: "",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 6,
          class: "right-side"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (show_dialog(true,null))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.create')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (robotsPublish(true))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "check",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.publish')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (robotsPublish(false))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "xmark",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.unpublish')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (robotsDelete(-1))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "trash-can",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_divider),
            _createVNode(_unref(ElTable), {
              ref: "robotTable",
              data: tableData.value,
              stripe: "",
              "show-overflow-tooltip": "",
              height: _unref(tableHeight),
              onSelectionChange: handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "expand" }, {
                  default: _withCtx((props) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                (props.row.system_role_content)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('robot.system_role_content')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.system_role_content), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.create_datetime)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('message.create_datetime')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                                      _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),props.row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 12,
                            style: {"align-content":"flex-start"}
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                (props.row.command)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.command')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.command), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.output_parser)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.output_parser')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.output_parser), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          (props.row.prompts && props.row.prompts.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_el_divider, { "content-position": "left" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('robot.prompts')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(ElTable), {
                                  data: props.row.prompts,
                                  "default-sort": { prop: 'item_sort', order: 'ascending' },
                                  class: "sub-table"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.prompt_id'),
                                      prop: "prompt_id",
                                      width: "60px",
                                      align: "center"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.name'),
                                      prop: "name",
                                      width: "170px",
                                      class: "multi-line-text"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createElementVNode("span", {
                                          innerHTML: _unref(formatText)(scope.row.name)
                                        }, null, 8, _hoisted_11)
                                      ]),
                                      _: 1
                                    }, 8, ["label"]),
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.description'),
                                      prop: "description",
                                      class: "multi-line-text"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createElementVNode("span", {
                                          innerHTML: _unref(formatText)(scope.row.description)
                                        }, null, 8, _hoisted_12)
                                      ]),
                                      _: 1
                                    }, 8, ["label"])
                                  ]),
                                  _: 2
                                }, 1032, ["data"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  width: "30"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", null, [
                      (row.publish)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                        : (_openBlock(), _createElementBlock("span", _hoisted_14))
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('robot.name'),
                  width: "300",
                  sortable: ""
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      innerHTML: scope.row.name
                    }, null, 8, _hoisted_15),
                    _cache[16] || (_cache[16] = _createTextVNode("  ")),
                    _createVNode(_component_el_tag, {
                      type: "warning",
                      size: "small",
                      effect: "plain",
                      round: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" (ID: " + _toDisplayString(scope.row.rb_id) + ") ", 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "description",
                  label: _ctx.$t('robot.description'),
                  class: "multi-line-text"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      innerHTML: _unref(formatText)(scope.row.description)
                    }, null, 8, _hoisted_16)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "modify_datetime",
                  label: _ctx.$t('message.modify_datetime'),
                  width: "150",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),row.modify_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "120",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(false,row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (robotsDelete(row.rb_id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "height"]),
            _createVNode(NewRobot, {
              modelValue: showDialog.value,
              "onUpdate:isShow": _cache[7] || (_cache[7] = ($event: any) => (showDialog.value = $event)),
              onRefreshList: fetchRobots
            }, null, 8, ["modelValue"]),
            _createVNode(NewRobot, {
              modelValue: showEditDialog.value,
              robot_info: cur_robot.value,
              "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (showEditDialog.value = $event)),
              onRefreshList: fetchRobots
            }, null, 8, ["modelValue", "robot_info"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[9] || (_cache[9] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[10] || (_cache[10] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50, 100, 200],
              layout: "total, sizes, prev, pager, next, jumper",
              total: totalItems.value,
              onSizeChange: _cache[11] || (_cache[11] = ($event: any) => (pageSize.value = $event)),
              onCurrentChange: handlePageChange
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})