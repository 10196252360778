// config.ts

// 定义配置接口，以确保类型正确性
interface Config {
    apiUserUrl: string;
    apiPromptUrl:string;
    apiRobotUrl:string;
    apiTopicUrl:string;
    apiApiUrl:string;
    apiLlmUrl:string;
    apiSysUrl:string;
    apiFileUrl:string;
    apiDynamicUrl:string;
    encryptionKey: string;
    apiKey: string;
    taskUrl:string

}

// 确保环境变量存在并且类型正确
const config: Config = {
    apiUserUrl: process.env.VUE_APP_SK_USERS_API_URL || '',
    apiPromptUrl: process.env.VUE_APP_SK_PROMPTS_API_URL || '',
    apiRobotUrl: process.env.VUE_APP_SK_ROBOTS_API_URL || '',
    apiTopicUrl: process.env.VUE_APP_SK_TOPICS_API_URL || '',
    apiApiUrl: process.env.VUE_APP_SK_API_API_URL || '',
    apiLlmUrl: process.env.VUE_APP_SK_LLM_API_URL || '',
    apiSysUrl: process.env.VUE_APP_SK_SYS_API_URL ||'',
    apiFileUrl: process.env.VUE_APP_SK_FILE_API_URL ||'',
    apiDynamicUrl: process.env.VUE_APP_SK_DYNAMIC_URL ||'',
    taskUrl:process.env.VUE_APP_SK_TASK_URL || '',
    encryptionKey: process.env.VUE_APP_ENCRYPTION_KEY || 'dont tell anyone',
    apiKey: process.env.VUE_APP_SK_API_KEY || 'dont tell anyone',
};

// 导出配置对象
export default config;
