import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","display":"flex","justify-content":"right"} }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { style: {"display":"flex","justify-content":"space-between","width":"100%","gap":"15px"} }
const _hoisted_5 = { class: "api-data" }
const _hoisted_6 = {
  class: "code-card",
  style: {"background":"white"}
}
const _hoisted_7 = { class: "hljs" }
const _hoisted_8 = { class: "api-data" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "api-data" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "api-data" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "api-data" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "dialog-header" }
const _hoisted_17 = { class: "api-data" }
const _hoisted_18 = {
  class: "message-ai chat-window",
  style: {"padding":"15px"}
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "api-data" }
const _hoisted_21 = {
  class: "message-ai chat-window",
  style: {"padding":"15px"}
}
const _hoisted_22 = ["innerHTML"]

import {computed, onMounted, reactive, ref, watch,onBeforeUnmount } from 'vue';
import {ElScrollbar, ElTable} from 'element-plus';
import {API} from "@/types/API";
import {autoHeight, formatDate, formatText, showLoading, showMessageBox, showNotification} from "@/services/tools";
import {useI18n} from "vue-i18n";
import {useApiStore} from "@/stores/apiStore";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import 'highlight.js/styles/github.css';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import {useClipboard} from '@/services/clipboard';
import {dynamic_api_exec} from "@/services/dynamicApi"
import Clipboard from 'clipboard'


export default /*@__PURE__*/_defineComponent({
  __name: 'api_check',
  setup(__props) {

const {t} = useI18n();
const apiStore = useApiStore();

// 解构时明确类型
const {
  initClipboard,
  updateButtonText,
  resetButtonAfterDelay
} = useClipboard()

// 响应式状态
const showChatBack = ref(false)
const currentPage = ref(1);
const pageSize = ref(20);
const totalItems = ref(0);
const tableData = ref<API[]>([]);
const tableHeight = autoHeight(590);
const chatHeight = autoHeight(400);
const codeHeight = autoHeight(162);
const apiTable = ref<InstanceType<typeof ElTable> | null>(null);
const command = ref("");
const language = ref("");
const userid = ref<number>();
const cur_api = ref<API>();
const searchData = reactive({keyword: ''});
const code_py = ref("")
const code_ja = ref("")
const code_ts = ref("")
const code_js = ref("")
const ai_answer = ref("")
const ai_reasoning = ref("")
const b_time = ref("")
const e_time = ref("")
const spend_second = ref(0.0)
const ai_loading = ref(false)




// 配置MarkdownIt用于高亮代码块
const md: MarkdownIt = new MarkdownIt({
  highlight: function (str, lang) {
    // 预处理：转换所有需要的字符到HTML实体
    const safeStr = str
        .replace(/&/g, '&#38;')
        .replace(/</g, '&#60;')
        .replace(/>/g, '&#62;')
        .replace(/"/g, '&#34;')
        .replace(/'/g, '&#39;')
        .replace(/\n/g, '&#10;');

    // 生成代码块的公共头部HTML
    const headerHtml = `<div class="code-header">
      <span class="code-lang">${lang || 'text'}</span>
      <button class="copy-button"
      :data-clipboard-text="safeStr"
      :data-original-text="t('robot.copy-code')">` + t('robot.copy-code') + `</button>
      </div>`;

    let codeHtml;

    // 如果指定了语言且支持，则高亮代码
    if (lang && hljs.getLanguage(lang)) {
      try {
        codeHtml = hljs.highlight(str, {language: lang}).value;
      } catch (error) {
        console.error('代码高亮失败:', error);
        codeHtml = md.utils.escapeHtml(str);
      }
    } else {
      codeHtml = md.utils.escapeHtml(str);
    }

    // 返回完整的代码块HTML
    return `${headerHtml}<div class="code-card"><pre class="hljs"><code>${codeHtml}</code></pre></div>`;
  }
});

const ini_code = async () => {
  code_py.value = `
\`\`\`Python
request_body = {
    "command": "${command.value}",
    "language": "${language.value}",
    "userid": ${userid.value}
}
api_url = '${window.location.origin}/dynamic/api${cur_api.value?.api_path}'
headers = {
    'Content-Type': 'application/json',
    Authorization: 'APIKEY-${cur_api.value?.api_key}',
}
response = requests.post(api_url, headers=headers, data=json.dumps(request_body))

print(response.status_code)
print(response.json())
\`\`\`
`
  code_ts.value = `
\`\`\`TypeScript
interface DynamicApiRequest {
  command: string;
  language: string;
  userid: number;
}
const requestBody: DynamicApiRequest = {
  command: "${command.value}",
  language: "${language.value}",
  userid: ${userid.value}
};

const apiUrl ='${window.location.origin}/dynamic/api${cur_api.value?.api_path}';
const response = await fetch(apiUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'APIKEY-${cur_api.value?.api_key}',
  },
  body: JSON.stringify(requestBody),
});
const data = await response.json();
console.log("Response Data:", data);
\`\`\`
`
  code_ja.value = `
\`\`\`Java
public class DynamicApiRequest {
    public static void main(String[] args) throws Exception {
        JSONObject requestBody = new JSONObject();
        requestBody.put("command", "${command.value}");
        requestBody.put("language", "${language.value}");
        requestBody.put("userid", ${userid.value});
        String apiUrl = "${window.location.origin}/dynamic/api${cur_api.value?.api_path}";
        String apiKey = "APIKEY-${cur_api.value?.api_key}";

        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create(apiUrl))
            .header("Content-Type", "application/json")
            .header("Authorization", apiKey)
            .POST(HttpRequest.BodyPublishers.ofString(requestBody.toString()))
            .build();
        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

        System.out.println("Response Status Code: " + response.statusCode());
        System.out.println("Response Body: " + response.body());
    }
}
\`\`\`
`
  code_js.value = `
\`\`\`JavaScript
const requestBody = {
  command: "${command.value}",
  language: "${language.value}",
  userid: ${userid.value}
};

const apiUrl = "${window.location.origin}/dynamic/api${cur_api.value?.api_path}";
const headers = {
  "Content-Type": "application/json",
  Authorization: "APIKEY-${cur_api.value?.api_key}"
};
fetch(apiUrl, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(requestBody)
})
  .then(response => response.json())
  .then(data => {
    console.log("Response Data:", data);
  })
  .catch(error => {
    console.error("Error:", error);
  });
\`\`\`
`
}

// Markdown 渲染函数
const renderedCode = (content: string) => {
  const formattedContent = content.replace(/\n/g, '  \n');
  return md.render(formattedContent);
};

// 包装数学公式
const wrapMathEquations = (content: string): string => {
  if (!content) return content
  // 处理行内公式
  content = content.replace(/\\\((.*?)\\\)/g, (match) => `$${match.slice(2, -2).trim()}$`);
  // 处理块级公式
  content = content.replace(/\\\[[^\]]+\\]/g, (match) => `$$${match.slice(2, -2).trim()}$$`);

  return content;
};

// 计算 JSON 数据
const jsonData = computed(() => ({
  command: command.value,
  language: language.value,
  userid: userid.value === undefined ? "" : userid.value
}));

// 格式化 JSON
const formattedJson = computed(() => JSON.stringify(jsonData.value, null, 4));

// 计算属性优化数据请求
const fetchAPIs = async () => {
  const loading = showLoading(t, document.querySelector('.el-table')!);
  try {
    totalItems.value = await apiStore.getAPIs(searchData.keyword, 1, currentPage.value, pageSize.value);
    tableData.value = apiStore.apiList;
  } finally {
    loading.close();
  }
};

// 处理搜索
const searchAPI = fetchAPIs;

// 处理分页变更
const handlePageChange = async (val: number) => {
  currentPage.value = val;
  await fetchAPIs();
};

const handleSelectionChange = async (val: API | undefined) => {
  cur_api.value = val;
  await ini_code()
};

// 复制到剪贴板
const copyToClipboard = async (copyText: string, tips: string) => {
  try {
    await navigator.clipboard.writeText(copyText);
    showNotification("success", t("message.copy"), tips + t("message.copy-success"));

  } catch (err) {
    showNotification("error", "复制失败", "无法复制到剪贴板");
  }
};

const api_test = async () => {
  if (!command.value || !userid.value || !cur_api.value) {
    try {
      await showMessageBox(t, t("apiconfig.api-test"), t("apiconfig.invalid-input"), true)
      return
    } catch {
      return
    }
  }
  // 记录开始时间
  const startTime = new Date();

  ai_loading.value = true
  try {
    b_time.value = formatDate(t,new Date(), "HH:mm:ss")
    e_time.value = ""
    ai_answer.value = ""
    ai_reasoning.value = ""
    showChatBack.value = true

    const response = await dynamic_api_exec(
        cur_api.value.api_path,
        cur_api.value.api_key,
        command.value,
        language.value,
        userid.value)

    ai_answer.value = response.content
    ai_reasoning.value = response.reasoning_content

  } catch (error: any) {
    ai_answer.value = error.message
  } finally {
    e_time.value = formatDate(t,new Date(), "HH:mm:ss")
    // 记录结束时间
    const endTime = new Date();
    // 计算时间差（毫秒）
    spend_second.value = (endTime.getTime() - startTime.getTime()) / 1000;
    ai_loading.value = false
  }
}

const openChatBackDialog = async () => {

}

const closeChatBackDialog = async () => {
  ai_answer.value = ""
  showChatBack.value = false
}

// 成功处理逻辑
const handleCopySuccess = (e: Clipboard.Event) => {
  const button = e.trigger as HTMLElement
  const originalText = button.dataset.originalText || button.textContent || ''

  updateButtonText(button, '✓ 复制成功')
  resetButtonAfterDelay(button, originalText)
}

// 错误处理（可选）
const handleCopyError = (e: Clipboard.Event) => {
  console.error('复制失败:', e.action)
}

// 监听 pageSize 变化，自动刷新数据
watch(pageSize, fetchAPIs);
watch([command, language, userid], ini_code)

// 组件挂载后加载数据
onMounted(() => {
  // new Clipboard('.copy-button');
  initClipboard({
    selector: '.copy-button',
    successCallback: (e) => {
      const button = e.trigger as HTMLElement
      button.textContent = '✓ ' + t('robot.copy-success')
      setTimeout(() => {
        button.textContent = t('robot.copy-code')
      }, 1000)
    }
  })

  const ps_key = localStorage.getItem("api_page_size");

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  Promise.all([fetchAPIs(), ini_code()])
});


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 30 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 10 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_form, {
              ref: "searchForm",
              model: searchData,
              inline: true,
              class: "search-form",
              style: {"margin-bottom":"15px"},
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "searchKey",
                  class: "keyword-input"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: searchData.keyword,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchData.keyword) = $event)),
                      placeholder: _ctx.$t('message.search-key'),
                      onKeyup: _withKeys(_unref(searchAPI), ["enter"]),
                      style: {"width":"400px"},
                      clearable: ""
                    }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _unref(searchAPI),
                  circle: "",
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _createVNode(_unref(ElTable), {
            ref_key: "apiTable",
            ref: apiTable,
            data: tableData.value,
            "row-key": "id",
            stripe: "",
            "highlight-current-row": "",
            "show-overflow-tooltip": "",
            height: _unref(tableHeight),
            onCurrentChange: handleSelectionChange
          }, {
            default: _withCtx(() => [
              _cache[9] || (_cache[9] = _createTextVNode(" > ")),
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: _ctx.$t('apiconfig.name'),
                sortable: ""
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("span", {
                    innerHTML: scope.row.name
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                prop: "api_path",
                label: _ctx.$t('apiconfig.api_path'),
                width: "100",
                class: "multi-line-text"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("span", {
                    innerHTML: _unref(formatText)(scope.row.api_path)
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                fixed: "right",
                label: _ctx.$t('message.action'),
                width: "280",
                align: "center"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    size: "small",
                    round: "",
                    plain: "",
                    onClick: ($event: any) => (copyToClipboard(scope.row.api_path,_unref(t)('apiconfig.api_path'))),
                    style: {"margin-left":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: _unref(faCopy),
                        class: "icon-text-button"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('message.copy') + _ctx.$t('apiconfig.api_path')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    size: "small",
                    round: "",
                    plain: "",
                    onClick: ($event: any) => (copyToClipboard(scope.row.api_key,_unref(t)('apiconfig.api_key'))),
                    style: {"margin-left":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: _unref(faCopy),
                        class: "icon-text-button"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('message.copy') + _ctx.$t('apiconfig.api_key')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data", "height"]),
          _createVNode(_component_el_pagination, {
            "current-page": currentPage.value,
            "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => ((currentPage).value = $event)),
            "page-size": pageSize.value,
            "onUpdate:pageSize": _cache[3] || (_cache[3] = ($event: any) => ((pageSize).value = $event)),
            "page-sizes": [20, 50, 100, 200],
            layout: "total, sizes, prev, pager, next, jumper",
            total: totalItems.value,
            onSizeChange: _cache[4] || (_cache[4] = ($event: any) => (pageSize.value = $event)),
            onCurrentChange: handlePageChange
          }, null, 8, ["current-page", "page-size", "total"]),
          _createVNode(_component_el_divider),
          _createVNode(_component_el_row, {
            gutter: 10,
            style: {"height":"400px"},
            class: "display-card"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_text, {
                size: "large",
                tag: "b",
                class: "input-text",
                style: {"width":"100%","text-align":"left"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("apiconfig.api-data")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_input, {
                modelValue: command.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((command).value = $event)),
                rows: 5,
                placeholder: _unref(t)('apiconfig.command'),
                type: "textarea",
                resize: "none",
                class: "input-text"
              }, null, 8, ["modelValue", "placeholder"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_input, {
                  modelValue: language.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((language).value = $event)),
                  placeholder: _unref(t)('apiconfig.language'),
                  type: "text",
                  class: "input-text"
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_el_input, {
                  modelValue: userid.value,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((userid).value = $event)),
                  placeholder: _unref(t)('apiconfig.userid'),
                  type: "number",
                  class: "input-text"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "code-header" }, [
                  _createElementVNode("span", { class: "code-lang" }, "JSON")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("pre", _hoisted_7, [
                    _createElementVNode("code", null, _toDisplayString(formattedJson.value), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 14 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { style: {"height":"35px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 12,
                class: "left-side"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_text, {
                    size: "large",
                    tag: "b",
                    class: "input-text"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("apiconfig.api-code")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 12,
                class: "right-side"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    round: "",
                    onClick: api_test
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("apiconfig.api-test")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tabs, {
            type: "border-card",
            style: {"margin-top":"15px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, null, {
                label: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("div", null, " Python ", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_unref(ElScrollbar), {
                    class: "chat-content",
                    height: _unref(codeHeight)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", {
                          class: "markdown-body",
                          innerHTML: renderedCode(code_py.value)
                        }, null, 8, _hoisted_9)
                      ])
                    ]),
                    _: 1
                  }, 8, ["height"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, null, {
                label: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("div", null, " Java ", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_unref(ElScrollbar), {
                    class: "chat-content",
                    height: _unref(codeHeight)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", {
                          class: "markdown-body",
                          innerHTML: renderedCode(code_ja.value)
                        }, null, 8, _hoisted_11)
                      ])
                    ]),
                    _: 1
                  }, 8, ["height"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, null, {
                label: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createElementVNode("div", null, " TypeScript ", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_unref(ElScrollbar), {
                    class: "chat-content",
                    height: _unref(codeHeight)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", {
                          class: "markdown-body",
                          innerHTML: renderedCode(code_ts.value)
                        }, null, 8, _hoisted_13)
                      ])
                    ]),
                    _: 1
                  }, 8, ["height"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, null, {
                label: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createElementVNode("div", null, " JavaScript ", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_unref(ElScrollbar), {
                    class: "chat-content",
                    height: _unref(codeHeight)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", {
                          class: "markdown-body",
                          innerHTML: renderedCode(code_js.value)
                        }, null, 8, _hoisted_15)
                      ])
                    ]),
                    _: 1
                  }, 8, ["height"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_dialog, {
            modelValue: showChatBack.value,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((showChatBack).value = $event)),
            width: "1200px",
            style: {"min-width":"1200px"},
            onOpen: openChatBackDialog,
            "append-to-body": "",
            center: "",
            draggable: "",
            "align-center": "",
            "close-on-click-modal": false,
            "show-close": false
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('apiconfig.test-result')), 1),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: closeChatBackDialog,
                  circle: "",
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "xmark" })
                  ]),
                  _: 1
                })
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: closeChatBackDialog,
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "xmark",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.close')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tag, {
                    type: "primary",
                    size: "large",
                    effect: "dark",
                    round: "",
                    style: {"margin-right":"20px","font-size":"16px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("apiconfig.begin")) + _toDisplayString(b_time.value), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_tag, {
                    type: "primary",
                    size: "large",
                    effect: "dark",
                    round: "",
                    style: {"margin-right":"20px","font-size":"16px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("apiconfig.end")) + _toDisplayString(e_time.value), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_tag, {
                    type: "warning",
                    size: "large",
                    effect: "dark",
                    round: "",
                    style: {"font-size":"16px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)("apiconfig.spend", {spend_second: spend_second.value})), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tabs, { style: {"width":"95%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tab_pane, null, {
                        label: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_unref(t)("chat.answer")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_17, [
                            _withDirectives((_openBlock(), _createBlock(_unref(ElScrollbar), {
                              class: "chat-content",
                              height: _unref(chatHeight)
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("div", _hoisted_18, [
                                  _createElementVNode("div", {
                                    class: "markdown-body",
                                    innerHTML: renderedCode(wrapMathEquations(ai_answer.value))
                                  }, null, 8, _hoisted_19)
                                ], 512), [
                                  [_vShow, ai_answer.value]
                                ])
                              ]),
                              _: 1
                            }, 8, ["height"])), [
                              [_directive_loading, ai_loading.value]
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_tab_pane, null, {
                        label: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_unref(t)("chat.reason")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _withDirectives((_openBlock(), _createBlock(_unref(ElScrollbar), {
                              class: "chat-content",
                              height: _unref(chatHeight)
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", {
                                    class: "markdown-body",
                                    innerHTML: renderedCode(wrapMathEquations(ai_reasoning.value))
                                  }, null, 8, _hoisted_22)
                                ], 512), [
                                  [_vShow, ai_reasoning.value]
                                ])
                              ]),
                              _: 1
                            }, 8, ["height"])), [
                              [_directive_loading, ai_loading.value]
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})