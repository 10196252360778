<script setup lang="ts">
import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {User} from '@/types/User';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useUserStore} from "@/stores/userStore";
import NewUser from "@/components/NewUser.vue";
import {useI18n} from "vue-i18n";

// 使用国际化功能
const {t} = useI18n();


// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<User[]>([]);  // 表格数据
const vips = ref<string>("-1");  // 会员状态

const tableHeight = autoHeight(190);  // 表格高度自适应
const userStore = useUserStore();  // 使用用户存储
const multipleSelection = ref<User[]>([]);  // 多选用户
const userTableRef = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增用户对话框显示
const showEditDialog = ref(false);  // 控制编辑用户对话框显示
const cur_user = ref<User>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData.keyword, Number(vips.value), currentPage.value, val);
    pageSize.value = val;
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData.keyword,  Number(vips.value), val, pageSize.value);
    currentPage.value = val;
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchUser = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData,  Number(vips.value), 1, pageSize.value);
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {User[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: User[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const usersDelete = async (cur_row: number) => {
  const userIds: number[] = [];
  try {
    const cur_user = await userStore.loadUser();
    if (!cur_user) {
      await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
      return;
    }

    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.last-one'));
      return;
    }

    // 检查当前用户是否在操作列表中或尝试操作自己
    if (cur_user.user_id === cur_row || multipleSelection.value.some(user => user.user_id == cur_user.user_id)) {
      await showMessageBox(t, t('message.del-title'), t('message.del-self'));
      return;
    }

    // 如果传入的行号有效且不是自己，添加到 userIds
    if (cur_row !== -1) {
      userIds.push(cur_row);
    } else {
      userIds.push(...multipleSelection.value.map(user => user.user_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await userStore.del_Users(userIds);

    tableData.value = tableData.value.filter(item =>
        !userIds.includes(item.user_id!)
    );
    totalItems.value = totalItems.value - userIds.length;
    const count = userIds.length;
    showNotification("success", t('message.del-success-title'), t('user.del-user-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_user - 是否是新增用户
 * @param {User | null} user_data - 当前用户数据
 */
const show_dialog = async (new_user: boolean, user_data: User | null) => {
  if (new_user) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (user_data) {
      cur_user.value = user_data;
    }
  }
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("user_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});
</script>

<template>

  <el-row>
    <el-col :span="20" class="left-side">
      <div class="pic-text-display">
        <el-radio-group v-model="vips" @change="searchUser(searchData.keyword)" style="white-space: nowrap;">
          <el-radio-button :label="$t('message.all')" value="-1" border/>
          <el-radio-button :label="$t('user.no-vip')" value="0" border/>
          <el-radio-button :label="$t('user.expired-vip')" value="1" border/>
          <el-radio-button :label="$t('user.new-vip')" value="2" border/>
          <el-radio-button :label="$t('user.pro-vip')" value="3" border/>
          <el-radio-button :label="$t('user.manager-role')" value="4" border/>
        </el-radio-group>
        <el-divider direction="vertical" style="height: 25px;"/>
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                      @keyup.enter="searchUser(searchData.keyword)"
                      style="width: 300px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchUser(searchData.keyword)" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
    </el-col>
    <el-col :span="4" class="right-side">

      <el-button-group style="white-space: nowrap">
        <el-button type="primary" @click="show_dialog(true,null)" round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('message.create') }}
        </el-button>
        <el-button type="danger" @click="usersDelete(-1)" round>
          <font-awesome-icon icon="trash-can" class="icon-text-button"/>
          {{ $t('message.delete') }}
        </el-button>
      </el-button-group>

    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-divider/>
      <el-table
          ref="userTableRef"
          :data="tableData"
          stripe
          show-overflow-tooltip
          :height="tableHeight"
          @selection-change="handleSelectionChange">
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column fixed="left" prop="name" :label="$t('user.nickname')" width="280">
          <template #default="{ row }">
            <span class="pic-text-display">
              <el-avatar :src="row.avatar_url" size="small" class="pic-text-display"></el-avatar>
              <span>{{ row.name }}</span>&nbsp;&nbsp;<el-tag type="warning" size="small" effect="plain" round>
            (ID:&nbsp;{{ row.user_id }})
          </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="login_name" :label="$t('user.login-name')" width="180">
          <template #default="{ row }">
            <span class="pic-text-display">
              <span class="pic-text-display">{{ row.login_name }}</span>
              <font-awesome-icon v-if="row.role === 1" icon="user-tie" style="color: #529b2e;"/>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="membership_end_date" :label="$t('user.vip-date')" width="150" sortable>
          <template #default="{ row }">{{ formatDate(t,row.membership_end_date, 'yyyy-MM-dd') }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" :label="$t('user.mobile')" width="120"></el-table-column>
        <el-table-column prop="create_datetime" :label="$t('user.create-date')" width="150" sortable>
          <template #default="{ row }">{{ formatDate(t,row.create_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="email" :label="$t('user.email')" width="auto"></el-table-column>
        <el-table-column prop="native_language" :label="$t('user.native_language')" width="auto"></el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="120" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(false,row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="usersDelete(row.user_id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <NewUser :modelValue="showDialog" @update:isShow="showDialog = $event" @refreshList="handleCurrentChange(1);"/>
      <NewUser :modelValue="showEditDialog" :userinfo="cur_user" @update:isShow="showEditDialog = $event"
               @refreshList="handleCurrentChange(1);"/>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next,jumper"
          :total="totalItems"
          @size-change="handleSizeChange(pageSize)"
          @current-change="handleCurrentChange(currentPage)"
      />

    </el-col>
  </el-row>

</template>

<style scoped>


.el-divider {
  background-color: #EBEDF0;
}


.el-row {
  height: auto;
}


</style>